.banner {
  max-width: 880px;
  padding: 24px;

  & > div {
    max-width: 540px !important;

    & > p {
      font-size: 14px !important;
    }
  }

  & img {
    right: -236px !important;
  }
}

.pageHeading,
.tableHeading {
  color: #131313 !important;
}

.tableHeading {
  margin: 40px 0 32px !important;
}

.docsHeading {
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  margin: 32px 0 8px;
  display: block;
}

.docsAttachBtn button {
  height: 40px;
  padding: 0 26px;
}

.subHeading {
  font-size: 18px;
  line-height: 24px;
  color: #595959;
}

.createRow {
  display: flex;
  gap: 16px;
  height: 32px;
}

.actions {
  margin-top: 64px;
}

.createRowType {
  width: 160px;
}

.createRowDescription {
  flex-grow: 1;

  & textarea {
    min-height: 32px !important;
    max-height: 32px !important;
    height: 32px !important;
    padding-top: 3px !important;
  }
}

.createRowTextArea {
  height: 32px;
}

.createRowAction {
  width: 100px;
  display: flex;
  justify-content: flex-end;

  & button {
    color: #582090;
    height: 32px;
  }
}

.riskFormWithFooter {
  :global {
    .evercity-ui-table-footer {
      padding: 8px 4px;
      padding-right: 12px;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      border-radius: 0px 0px 8px 8px;
    }

    .evercity-ui-table-tbody .evercity-ui-table-cell {
      border-radius: 0 !important;
    }
  }
}
