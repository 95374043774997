@import "../../styles/theme.less";

.table {
  margin-top: 40px;
}

.table thead {
  background-color: @gray-4;
}

.table th:first-child {
  border-radius: 8px 0 0 0 !important;
}

.table th:last-child {
  border-radius: 0 8px 0 0 !important;
}

.actionIcons {
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid @gray-5;
  border-radius: 2px;
  cursor: pointer;
}

.saveButton {
  width: 95px;
  box-sizing: border-box;
  margin-top: 60px;
}

.col {
  color: @gray-7;
}

.file {
  color: @blue-6;
}

.fileIcon {
  color: @gray-13;
}
.description {
  margin-bottom: 20px;
}

.linkBack {
  display: block;
  font-size: @font-size-lg;
  line-height: @line-height-lg;
  margin-bottom: 30px;

  &__icon {
    margin-right: 15px;
  }
}

.upload {
  margin-bottom: 40px;
}

.templates {
  margin-bottom: 100px;
}