@import "../../styles/theme.less";

.link {
  &--noMargin {
    margin-bottom: 0 !important;
  }
  &--size-large {
    font-size: @font-size-lg;
  }
  &--size-small {
    font-size: @font-size-base - 2;
  }
  &--size-extra-large {
    font-size: @font-size-lg + 4;
  }
  &--size-extra-small {
    font-size: @font-size-lg - 4;
  }

  &--theme-secondary {
    &:hover {
      color: color(~`colorPalette("@{text-color-secondary}", 7) `) !important;
    }
    &:active {
      color: color(~`colorPalette("@{text-color-secondary}", 5) `) !important;
    }
  }

  &--theme-button {
    background-color: @blue-6;
    color: @white !important;
    padding: 8px 30px;
  }

  &--theme-round_button {
    background-color: @blue-new;
    border-radius: 6px;
    color: @white;
    padding: 5px 16px;
  }
}

.icon {
  margin-right: 8px;
}
