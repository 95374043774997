@import "../../styles/theme.less";

.textarea {
  position: relative;
  width: 100%;

  &-roundCorners {
    &:global(.ant-input), :global(.ant-input) {
      border-radius: 8px;
    }
  }

  &:global(.ant-input), :global(.ant-input) {
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    padding: 8px 12px 8px 12px;
    font-style: normal;
    font-weight: normal;
    min-height: 90px;
    font-size: 16px;
    line-height: 24px;
    color: @gray-9;
  }

  &::after {
    display: none;
    position: absolute;
    bottom: 20px;
    right: 10px;
  }

  &:focus-within {
    &::after {
      display: block;
    }
  }

}
