@import "../../styles/theme.less";

.paragraph {
  &--noMargin {
    margin-bottom: 0 !important;
  }
  &--block {
    display: block;
  }
  &--size-extra-large {
    font-size: @font-size-lg + 2;
    line-height: @line-height-base;
  }
  &--size-large {
    font-size: @font-size-lg;
    line-height: @line-height-base;
  }
  &--size-small {
    font-size: @font-size-base - 2;
    line-height: @line-height-base;
  }
  &--size-extra-small {
    font-size: @font-size-base - 4;
    line-height: @line-height-base;
  }
}
