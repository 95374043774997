@import "../../styles/theme.less";

.list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 10px 20px 0;
  padding-left: 0;
  width: 100%;
}

.item {
  font-size: 16px;
  margin-bottom: 12px;
  position: relative;
  padding-left: 22px;

  &:not(:last-of-type) {
    margin-right: 48px;
  }
}

.marker {
  --size: 12px;

  position: absolute;
  left: 0;
  top: 6px;
  border-radius: 50%;
  display: inline-block;
  height: var(--size);
  overflow: hidden;
  width: var(--size);
}

.secondary {
  color: @gray-7!important;
}

.collapse {
  margin-bottom: -10px;
  padding-left: 24px!important;
  margin-right: 20px;
  margin-top: 10px!important;
  max-width: 100%;
  position: relative;
  width: 100%;
}

.collapseInner {
  margin: 8px 0 22px;
}