@import "../../styles/theme.less";


.scroll_wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: 560px;
  padding-right: 55px;
  padding-left: 5px;
  padding-top: 26px;
  padding-bottom: 40px;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: @gray-2;
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: @blue-6;
  }
}