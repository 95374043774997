@import "../../styles/theme";

.table {
  :global {
    th {
      border-bottom-color: #000000 !important;
    }
  }
}
.col {
  cursor: pointer;
  border-bottom-color: #e5e5e6 !important;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  &--progressBar {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  &--companyName {
    width: 10%;
    padding-left: 0 !important;
  }
  &--actions {
    text-align: center !important;
  }
}
