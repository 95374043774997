@import "../../styles/theme.less";

.container {
  --max-width: 1064px;
  --min-width: 320px;
  --width: 100%;

  --padding: 20px;
  @media @breakpoint-mobile-max {
    --padding: 10px;
  }

  margin: 0 auto;
  width: var(--width);
  min-width: var(--min-width);
  padding-left:  var(--padding);
  padding-right: var(--padding);

  &:not(.wide) {
    max-width: calc(var(--max-width) + var(--padding) * 2);
  }
}

.margins {
  margin: 40px auto 76px;

  @media @breakpoint-mobile-max {
      margin: 20px auto 60px;

  }

}