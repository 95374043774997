@import "../../styles/theme.less";

.mapWidget {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity @animation-duration-base @ease-base-out;
  &--loaded {
    opacity: 1;
  }
}
.fullMapControl {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}
.zoomControl {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.attributionController {
  position: absolute;
  bottom: 17px;
  right: 17px;
  & > * {
    bottom: 0;
    right: 0;
  }
}
