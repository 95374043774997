@import "../../styles/theme.less";

.panelContainer {
  border-bottom: 2px solid @gray-5 !important;
  &:global(.ant-collapse-item-active) :local(.panelTitleIcon) {
    transform: translateY(-50%) rotateX(180deg) !important;
  }
  :global {
    .ant-collapse-header {
      padding: 16px 50px 16px 0 !important;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: @gray-13;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:before,
      &:after {
        content: none !important;
      }
    }
    .ant-collapse-content-box {
      padding: 0 !important;
      padding-bottom: 20px !important;
    }
  }
}
.panelTitleIcon {
  color: @gray-7;
  width: 13px;
  height: 7px;
  transform: translateY(-50%) !important;
  transition: transform @animation-duration-base @ease-base-out;
}
