@import "../../styles/theme.less";
@import "point.less";

.uploadList {
  :global {
    .ant-upload-list-text {
      margin-bottom: 0 !important;
    }
  }
}

.files {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
