@import "../../styles/theme.less";

.colorIcon {
  color: @blue-5 !important;
}

.copyIcon {
  cursor: pointer;
}

.close {
  font-size: 16px;
}

.tooltip {
  display: flex;
  gap: 12px;
  align-items: center;

  span {
    color: @gray-1;
  }
}

.fileTag {
  display: inline-flex !important;
  align-items: center;
  padding: 7px !important;
  border: #f0f0f0 1px solid !important;
  border-radius: 6px !important;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  max-width: 200px;
  overflow: hidden;
  margin-right: 0 !important;

  &:not(:hover) {
    background-color: @gray-1 !important;
  }

  &:hover {
    background-color: @gray-4;
  }
  :global {
    .anticon + span {
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    .ant-tag-close-icon {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}

.tooltip {
  width: auto !important;
}
.fileName {
  overflow: hidden;
}
