@import "../../styles/theme.less";

.mapContainer {
  --controllersHorizontalPadding: 40px;
  --controllersBottomPadding: 40px;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;

  transition: opacity @animation-duration-base @ease-base-out;
  &--loaded {
    opacity: 1;
  }
  &--pointFeatureHovered {
    cursor: default;
  }
  @media @breakpoint-mobile-max {
    --controllersHorizontalPadding: 20px;
    --controllersBottomPadding: 20px;
  }
}
.map {
  position: relative;
  width: 100%;
  height: 100%;
  flex-shrink: 1;
}
.projectPopup {
  top: 0;
  left: 0;
  z-index: 100;
  width: 370px;
  height: 100%;
  flex-shrink: 0;
  background-color: @gray-9;

  @media @breakpoint-mobile-max {
    width: 100%;
    position: absolute;
  }
}

.roundedBtn {
  border-radius: 8px !important;
  border: none !important;
}

.topLeftControl {
  position: absolute;
  top: var(--controllersHorizontalPadding);
  left: var(--controllersHorizontalPadding);
  z-index: 20;
}
.topRightControl {
  position: absolute;
  top: var(--controllersHorizontalPadding);
  right: var(--controllersHorizontalPadding);
  z-index: 20;
}
.bottomCenterControl {
  position: absolute;
  bottom: var(--controllersBottomPadding);
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}
.zoomController {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--controllersHorizontalPadding);
  z-index: 20;
}
.attributionController {
  position: absolute;
  bottom: 17px;
  right: 30px;
  & > * {
    bottom: 0;
    right: 0;
  }
  @media @breakpoint-mobile-max {
    right: 15px;
  }
}
.legendController {
  position: absolute;
  z-index: 10;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);
  width: 1079px;
  max-width: calc(100% - var(--controllersHorizontalPadding) * 2);
  will-change: transform; //fix z-index bug
  @media @breakpoint-mobile-max {
    bottom: 0;
    max-width: 100%;
  }
}
.featurePopup {
  position: relative;
  z-index: 100;
}
.activeFeaturePin {
  width: 42px;
  height: 42px;
  z-index: 5 !important;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
