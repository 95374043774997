@import "../../styles/theme.less";

.container {
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  &--hidden {
    opacity: 0;
    visibility: hidden;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    transform: translate(-50%, -50%);
    z-index: 0;
    transition: background-color @animation-duration-base @ease-base-out;
  }
  &:hover:after {
    background-color: @gray-3;
  }
}
.icon {
  position: relative;
  z-index: 10;
  transition: transform @animation-duration-base @ease-base-out,
    color @animation-duration-base @ease-base-out;
  font-size: 12px;
  color: @gray-9;
  transform-origin: center center;
  &:hover {
    color: @gray-8;
  }
  &:active {
    color: @gray-10;
  }
  &--expanded {
    transform: rotateZ(90deg);
  }
}
