@import "../../styles/theme.less";

.section {
  background-color: @gray-2;
  border-radius: 8px;
  padding: 20px;

  ul {
    list-style-type: none;
    line-height: 2;
  }

  ul li::marker, ul {
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }

  :global {
    .anticon-check {
      color: @blue-6 !important;
    }
    .ant-row > span {
      margin-bottom: 28px !important;
      font-size: 16px;
    }
  }

  &__head {
    h3 {
      margin-bottom: 0 !important;
    }
    border-bottom: 1px solid @gray-5;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &--withDescription {
      h3 {
        margin-bottom: 10px !important;
      }
    }
  }

  &__desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

}