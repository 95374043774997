@import "../../styles/theme.less";

.titleInfoBtn {
  display: block !important;
}

.listItem {
  display: flex;
  vertical-align: top;
  &__index {
    width: 30px;
    height: 30px;
    border: 2px solid @gray-5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0;
  }
}
.formItem {
  margin-bottom: 0 !important;
  :global {
    .ant-form-item-explain {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
}
