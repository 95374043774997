@import "../../styles/theme.less";

.fileCard {
  --padding: 5px;

  display: flex;
  align-items: center;
  background-color: @gray-2;
  border-radius: 4px;
  height: 78px;
  padding: 10px 5px 10px 20px;
  overflow: hidden;
  width: 300px;

  @media @breakpoint-mobile {
    width: 260px;
  }

  &--vertical {
    width: 224px;
    height: 225px;
    padding: 30px 25px 36px;
    align-items: start;
  }
}

.cardFooter {
  position: relative;
  left: calc(-1 * var(--padding));
}

.middleCol {
  padding-left: 25px;
}

.textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  &--vertical {
    font-size: 16px;
  }
}

.fileIcon {
  font-size: 24px;
  color: @gray-9;
  padding: 0 !important;
  display: block;
  margin-top: 4px;
}
.downloadBtn {
  width: auto !important;
  height: auto !important;

  &:global(.ant-btn) {
    padding: 5px !important;
  }
  :global {
    .anticon {
      font-size: 13px !important;
    }
  }
}

.status {
  &--verified {
    color: @green-6 !important;
  }
  &--draft {
    color: @text-color-secondary !important;
  }
}
