@import "../../styles/theme.less";


.container {

    .input {
      border-radius: 8px;
      border: 1px solid @gray-5 ;
      color: @gray-9;
    }
  .add {
    color: @blue-6;
  }
}


.items {
  padding-left: 0;
  margin-bottom: 8px;
  list-style-position: inside;
}

.item {
  padding-left: 20px;
  background: @gray-4;
  margin-bottom: 2px;

  &::marker {
    font-size: 8px;
  }
}

.point {
  width: 98%;
  display: inline-flex;
  justify-content: space-between;

  &__action {
    background: transparent !important;
    border: none !important;
    color: @gray-7 !important;
  }

  &__input {
    width: 100%;
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }
}