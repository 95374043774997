@import "../../styles/theme.less";
.menu {
  border-right: none !important;
  box-shadow: @box-shadow-base !important;
  border-radius: 6px;
  overflow: hidden;
}
.menuItem {
  color: @gray-9;
  margin: 0 !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center;

  &:hover,
  &:active {
    color: @gray-9 !important;
    background-color: @gray-2 !important;
  }
}
