.submitBtn {
  padding-left: 32.5px !important;
  padding-right: 32.5px !important;
}
.submitWrapper {
  margin-top: 35px !important;
  margin-bottom: 0 !important;
}

.formItem {
  margin-bottom: 0 !important;
  :global {
    .ant-form-item-label {
      white-space: nowrap;
      overflow: visible;
    }
  }
}
