@import "../../styles/theme.less";

.tabsBar {
  :global {
    .ant-tabs-tab {
      padding-left: 10px;
      padding-right: 10px;
      &:first-child {
        padding-left: 0;
      }
    }
    .ant-tabs-nav {
      margin-bottom: 40px !important;
    }
    .ant-tabs-nav::before {
      display: none !important;
    }
  }
}

.tab {
  padding-right: 5px;
}
