@import "../../styles/theme.less";
.searchInput {
  border-radius: 0 !important;
  border-bottom: 2px solid @blue-6 !important;
  padding: 0 !important;
  padding-bottom: 11px !important;
  input {
    font-size: 16px !important;
  }

  :global {
    .ant-input-prefix {
      margin-right: 10px;
      font-size: 18px;
      color: @gray-7;
      transition: color @animation-duration-base @ease-base-out;
    }
  }
  &:hover {
    input:placeholder {
      color: @gray-13 !important;
    }
  }

  &:global(.ant-input-affix-wrapper-focused),
  &--filled {
    :global .ant-input-prefix {
      color: @gray-13 !important;
    }
  }
}

.closeIcon {
  font-size: 17.5px;
  opacity: 0;
  visibility: hidden;
  transition-duration: @animation-duration-base;
  transition-timing-function: @ease-base-out;
  transition-property: opacity, visibility, fill;
  cursor: pointer;
  fill: @gray-7;
  &--active {
    opacity: 1;
    visibility: visible;
  }
  &:hover,
  &:active {
    fill: @gray-13;
  }
}
