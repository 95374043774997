@import "../../styles/theme.less";

.inlineNoteList {
  list-style-type: "— " !important;
  margin-bottom: 0 !important;
}

.text {
  font-size: 16px;
  line-height: 130%;
  color: #000000;
  opacity: 0.6;
}
.marginBottom {
  display: block;
  margin-bottom: 20px !important;
}

.checkboxBlock {
  display: flex;
  flex-direction: column;
}

.leftMargin {
  margin-left: 40px;
}

.radioGroup {
  margin-bottom: 35px;
}

.input {
  border: 1px solid #D9D9D9 !important;
  height: 40px;
}

.input:focus {
  border: 1px solid #595959 !important;
}

.input:hover {
  border: 1px solid #595959 !important;
}