@import "../../styles/theme.less";

.btn {
  font-size: 16px;
  cursor: help !important;
  color: @primary-6 !important;
  transition: color @animation-duration-base @ease-base-out;
  display: inline-block;
  &:hover {
    color: @primary-5 !important;
  }
  &:active {
    color: @primary-7 !important;
  }
}
