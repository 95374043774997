@import "../../styles/theme.less";

.codeTag {
  cursor: pointer;
  padding: 0 4px;
  height: 20px;
  display: flex;
  align-items: center;
  outline: none;
  border: 1px solid @gray-5;
  border-radius: 2px;
  color: @gray-7;
  font-size: 11px;
  font-weight: @font-weight-bold;
  transition: color @animation-duration-base @ease-base-out;

  &:hover:not([disabled]) {
    border-color: @gray-8;
    color: @gray-8;
  }
  &:active:not([disabled]) {
    border-color: @gray-9;
    color: @gray-9;
  }
  &[disabled] {
    opacity: 0.3;
    cursor: default;
  }

  &--selected {
    color: @blue-5;
    border-color: @blue-5;

    &:hover:not([disabled]) {
      color: @blue-4;
      border-color: @blue-4;
    }
    &:active:not([disabled]) {
      color: @blue-6;
      border-color: @blue-6;
    }
    &[disabled] {
      opacity: 1;
    }
  }
}

.tooltipWrapper {
  font-size: 0;
  display: inline-flex;
}
