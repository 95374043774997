@import "../../styles/theme.less";

.footer {
  background-color: @gray-4 !important;
  padding: 45px 0 50px !important;

  @media @breakpoint-mobile {
    padding: 45px 0 47px !important;
  }
}

.grid {
  display: grid;
  justify-content: space-between;
  max-width: 100%;

  @media @breakpoint-mobile-max {
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 80px;
    grid-row-gap: 60px;
  }

  @media @breakpoint-mobile {
    grid-template-columns: repeat(3, auto);
  }
}

.socialsItem {
  display: flex;

  @media @breakpoint-mobile-max {
    grid-column: 1 / 3;
    text-align: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  @media @breakpoint-mobile {
    flex-direction: column;
  }
}

.nav {
  margin-bottom: 0;
}

.navTitle {
  margin-bottom: 20px;
}

.navItem {
  margin: 0;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.link {
  color: @gray-8;

  &:not(.undecorated) {
    text-decoration: underline;
  }

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.undecorated {
  text-decoration: none;
}

.accent {
  color: @gray-13;
  font-size: 16px;
}

.linkTitle {
  color: @gray-13;
  font-weight: @font-weight-bold;
  font-size: 20px;
  text-decoration: none;
}

.socials {
  margin-top: 10px;
}

.copyright {
  margin: 70px 0 0;
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  color: @gray-13;

  @media @breakpoint-mobile {
    color: @gray-6;
  }
}
