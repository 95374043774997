@import "../../styles/theme.less";

.title {
  &--h2 {
    font-size: @font-size-title-h2 !important;
    line-height: @line-height-title-h2 !important;
  }

  &--h3 {
    font-size: @font-size-title-h3 !important;
    line-height: @line-height-title-h3 !important;
  }
  &--medium {
    font-size: @font-size-title-medium !important;
    line-height: @line-height-title-medium !important;
  }
  &--small {
    font-size: @font-size-title-small !important;
    line-height: @line-height-title-small !important;
  }
  &--noMargin {
    margin-bottom: 0 !important;
  }
}