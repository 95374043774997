@import "../../styles/theme.less";

.text {
  color: @gray-10 !important;

  &-secondary {

  }
}

.list {
  display: flex !important;
  gap: 15px;

  :global {
    .ant-checkbox-wrapper {
      margin: 0 !important;
    }
  }


  &--vertical {
    flex-direction: column;
  }

  &--horizontal {
    flex-direction: row;
  }

  &__specify {
    margin-top: 5px !important;
  }

}
