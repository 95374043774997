@import "../../styles/theme.less";

.intro {
  margin-bottom: 48px;
  display: block;
  max-width: 90%;
}

.close {
  font-size: 20px;
}

.counter {
  background-color: @gray-1;
  border-radius: 50px;
  color: @blue-5;
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.drawer {
  :global {
    .ant-drawer-body {
      display: flex !important;
      flex-direction: column;
    }
    .ant-tabs,
    .ant-tabs-content {
      overflow: hidden;
      height: 100%;
    }
  }
}
