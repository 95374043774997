@import "../../styles/theme.less";

.select {

  &--multiple {
    && {
      width: 100%;
    }
  }

  &--secondary {
    :global {
      .ant-select-selector {
        display: flex;
        box-sizing: border-box;
        padding: 8px 12px;
        align-items: center;
        min-height: 40px;
        border: 1px solid @gray-5 !important;
        border-radius: 8px !important;
      }

      .ant-select-arrow {
        color: @gray-13;
      }

      .ant-select-selection-item {
        font-size: @font-size-lg;
        line-height: @line-height-lg;
      }
    }
  }

  &--capitalize {
    :global {
      .ant-select-selection-item::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.dropdown {
  &--capitalize {
    :global {
      .ant-select-item-option-content::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
