.container {
  :global {
    .ant-select-arrow {
      width: auto;
      left: 0;
      top: 5px;

      .ant-avatar-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-select-selection-placeholder, .ant-select-selection-item {
      margin-left: 25px;
    }
  }
}