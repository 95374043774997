.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 40px;
  overflow: auto;
}
.dropzone {
  flex-shrink: 0;
}
