@import "../../styles/theme.less";

.filters {
  margin: 10px 0 50px !important;

  @media @breakpoint-mobile-max {
    display: none;
  }
}

.header {
  margin: 20px 0 30px;
}

.selectFilter {
  :global {
    .ant-select-selection-placeholder {
      font-size: 14px;
      line-height: 22px;
      color: @gray-9;
    }

    .ant-select-selection-item {
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .ant-select-selector {
      padding-left: 0 !important;
    }
  }
}

.dropdownMenu {
  :global {
    .ant-select-item-option-content {
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.footer {
  display: grid;
  grid-template-columns: 1fr min-content;
  justify-content: space-between;
}

.buttons {
  display: flex !important;
}

.button {
  width: 32px;
  height: 32px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.menuItem {
  text-transform: capitalize;
}
