@import "../../styles/theme.less";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1.5px dashed @gray-5;
  width: 100%;
  height: 240px;
  background-color: @white;
  border-radius: @border-radius;


  &__description {
    color: @gray-7;
    font-weight: 400;
    font-size: @font-size-lg;
  }
}

.upload {
  &__btn {

  }
}