@import "../styles/theme.less";

// Form
.ant-form-large .ant-form-item-label > label {
  height: auto !important;
}
.ant-form-item-required {
  &:before {
    display: none !important;
  }
}
.ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: @font-size-base - 2px !important;
}
.ant-form-item {
  margin-bottom: 30px;
}
.ant-form-item-tooltip {
  color: @blue-6 !important;
}

.ant-form-item-has-feedback.ant-form-item-has-success {
  .ant-form-item-children-icon {
    display: none !important;
  }
  .ant-select-arrow {
    right: 12px !important;
  }
}

// Tooltip
.ant-tooltip-inner {
  border-radius: 10px !important;
}
.ant-tooltip-placement-rightBottom {
  .ant-tooltip-content {
    // margin-top: 10px;
  }
  .ant-tooltip-arrow {
    // bottom: 10px !important;
  }
}

.ant-radio-wrapper {
  margin-right: 24px !important;
}

// Statistic
.ant-statistic-title {
  color: @gray-7;
  font-size: 16px;
  margin-bottom: 0;
}

.ant-statistic-content {
  font-size: 16px;
}

.ant-statistic-content-value {
  color: @gray-10;
  font-size: 16px !important;
}

// Charts
.recharts-legend-item-text {
  color: @gray-10!important;
}

.recharts-cartesian-grid-horizontal {
  line {
    stroke: @gray-5!important;
  }
}

// Card
.ant-card-bordered {
  border-radius: @border-radius-card !important;
  border: @border-card;
  height: 100%;
  max-width: 100%;
}

.ant-card-body {
  overflow: visible !important;
}

// Tabs
.ant-tabs {
  overflow: visible;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px @divider-color solid;
}

// Statitics
.ant-statistic-content-value {
  color: @gray-13;
}

.ant-page-header-heading-title {
  font-size: 30px!important;
  line-height: 38px!important;
}

// Drawer
.ant-drawer-header {
  padding-bottom: 0 !important;
}

.ant-drawer-footer {
  padding: 20px !important;
  border: none !important;
}

.ant-drawer-title {
  font-size: 30px !important;
  line-height: 38px !important;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

.ant-drawer-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-modal-mask {
  background-color: rgba(35, 17, 79, 0.75) !important;
}

.ant-progress-text {
  font-size: 12px;
  line-height: 130%;
  color: #69676F;
}
