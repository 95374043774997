@import "../../styles/theme.less";

.container {
  overflow: hidden;
  background-color: @gray-3;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: background-color @animation-duration-base @animation-duration-base;
  cursor: pointer;
  &--transparent {
    background-color: transparent;
  }
  &--status-notAvailable,
  &--status-added {
    cursor: default !important;
  }
}
.titleContainer {
  width: 100%;
  padding-right: 30px;
}

.title {
  margin: 0 !important;
  display: inline;
  vertical-align: middle;
}
.tag {
  padding: 0.5px 8px;
  color: @gray-13;
  flex-shrink: 0;
  display: inline;
  vertical-align: middle;
  text-transform: lowercase;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: @gray-13;
  border-radius: @border-radius-base;

  each(.activitiestypes(), {
    &--@{key}{
      background-color: @value;
    };
  });
  &--default {
    background-color: @gray-1;
    border: 1px solid @gray-6;
  }
}
.specs {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-flow: row;
  row-gap: 3px;
  column-gap: 3px;
}
.specKey {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 125%;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.specValue:extend(.specKey) {
  color: @gray-8;
}

.btn {
  flex-grow: 0;
  &--success {
    background-color: @success-color !important;
    border-color: @success-color !important;
    @hover-color: color(~`colorPalette("@{success-color}", 5) `);
    @active-color: color(~`colorPalette("@{success-color}", 7) `);
    &:hover {
      background-color: @hover-color !important;
      border-color: @hover-color !important;
    }
    &:active {
      background-color: @active-color !important;
      border-color: @active-color !important;
    }
    &:after {
      background: @active-color !important;
    }
  }
}
