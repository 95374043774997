.modal {
  padding: 60px;

  background: #FFFFFF;
  border-radius: 10px;

  pointer-events: auto;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px
}

.modalTitle {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;

  color: #262626;
}