@import "../../styles/theme.less";

.subtitle {
  color: @gray-7;
  margin: 0;
}
.radioGroup {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  margin-top: 30px !important;
  margin-bottom: 40px !important;
}
.nextStepBtn {
  margin-top: 50px;
}
