@import "../../styles/theme";

.children {
  padding-left: 30px;
}

.symbol {
  font-size: @font-size-lg;
  &:global(.ant-typography-expand) {
    margin-left: 0;
  }
}
