@import "../../styles/theme.less";

.btn {
  color: @blue-6 !important;
  padding: 0 !important;
  &:hover {
    background-color: transparent !important;
    color: @blue-7;
  }
  &:active {
    color: @blue-5;
  }
}
