@import "../../styles/theme.less";

.card
{
  width: 100%;
  max-width: 520px !important;
  min-height: 200px;

  @media @breakpoint-mobile
  {
    padding: calc(53px - 24px) calc(60px - 24px) calc(60px - 24px)!important;
    height: auto !important;
  }

  @media @breakpoint-mobile-max
  {
    // padding: 0 0 40px !important;
    position: absolute;
    border: none !important;
    border-radius: 0 !important;
    :global
    {
      .ant-card-body
      {
        padding-bottom: 80px!important;
      }
    };
  }
}

.title {
  margin-bottom: 0!important;
}

.header {
  margin-bottom: 50px;
}

.subtitle {
  font-size: @font-size-lg;
}

