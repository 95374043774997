@import "../../styles/theme.less";

.btn {
  font-size: 12px;
  &:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
