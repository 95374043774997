@import "../../styles/theme.less";

.container {
  & + & {
    margin-top: 80px;

    @media @breakpoint-mobile-max {
      margin-top: 40px;
    }
  }
}

.title {
  @media @breakpoint-mobile-max {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

.value {
  display: inline-block;
  // &::first-letter {
  //   text-transform: uppercase !important;
  // }
}
