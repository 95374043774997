.page {
  :global {
    .evercity-ui-form-item {
      margin: 0;
    }
  }

  & .attach {
    :global {
      .evercity-ui-btn {
        height: 40px;
        width: 186px;
      }
    }
  }
}
