@import "../../styles/theme.less";

.container {
  margin-bottom: 35px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-weight: @font-weight-bold;
    font-size: @font-size-lg;
    line-height: @line-height-lg;
  }

  &__description {
    display: block;
    margin-top: 15px;
  }

  &__content {
    padding-top: 15px;

    &--describe {
      padding-top: 0;
    }
  }

  &__border {
    &--bottom {
      padding-bottom: 30px;
      border-bottom: 1px solid @gray-5;
    }
  }
}