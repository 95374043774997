@import "../../styles/theme.less";


.summary {
  &__subtitle {
    margin-bottom: 20px !important;
  }

  &__download {
    margin-top: 20px;
    cursor: pointer;
    font-size: @font-size-lg;
    color: @blue-6 !important;
  }
}