@import "../../styles/theme";

.productPrimaryBtn {
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    color: @white;
    background: @primary-color;
    text-transform: uppercase;
    font-size: 24px;
    height: 54px;
  }
}
