@import "../../styles/theme.less";

.breadcrumb{
  color: @gray-7 !important;
  text-decoration: none;
}

.title{
  display: flex;
  align-items: baseline;
  margin-top: 30px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 48px;
}

.step {
  &__title {
    margin-bottom: 20px !important;
  }
}

.framework_form {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}