@import "../../styles/theme.less";
@import "./CellThemes.less";

.headerCell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 18px;
  padding-bottom: 9px;
  height: 267px; //writing-mode не работает при рендере в canvas, ставим фикс высоту
  max-width: 88px;
  margin-left: auto;
  margin-right: auto;
  &__title {
    // display: inline-flex;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    width: 198px;

    // writing-mode: vertical-rl;
    // max-height: 198px;

    transform-origin: right center;
    transform: translate(-50%, -50%) rotate(-90deg);
    text-align: right;
  }
  &__measure {
    opacity: 0.6;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    margin-top: 13px;
  }
  &--horizontal {
    max-width: 100%;
  }
  &--horizontal &__title {
    // writing-mode: unset;
    transform: none;
    max-width: 143px;
    text-align: center;
    transform: none;
    height: 100%;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  &--theme-1 {
    .theme-1();
  }
  &--theme-2 {
    .theme-2();
  }
}
