@import "../../styles/theme.less";

.container {
  width: 150px;
  line-height: 0;
  &--empty {
    .percent {
      color: @gray-6 !important;
    }
    .text {
      display: none !important;
    }
    .line :global {
      .ant-progress-inner,
      .ant-progress-bg {
        background-color: @gray-5 !important;
      }
    }
  }
  &--responsive {
    width: 100%;
  }
}

.percent {
  color: #a0d911 !important;
  // margin-right: 10px;
}

.text {
  color: @gray-13 !important;
  white-space: nowrap;
}

.stats {
  display: flex;
  justify-content: space-between;
}
.line {
  line-height: 0 !important;
  :global {
    .ant-progress-inner {
      border-radius: 0 !important;
      background-color: @gold-4 !important;
    }
    .ant-progress-bg {
      height: 3px !important;
      background-color: @lime-6 !important;
    }
  }
}
