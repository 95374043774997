@import "../../styles/theme.less";

.category {
  &__add {
    border-color: @blue-5 !important;
    color: @blue-5 !important;
  }
}

.choose {
  width: 100%;
}

.collapseCategory {
  :global {
    .ant-collapse-arrow {
      background-color: @gray-4;
      border-radius: 4px;
      padding: 6px;
    }

    .ant-collapse-item-active .ant-collapse-header div > .ant-collapse-arrow {
      background-color: @gray-5;
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;


      border: 1px solid @gray-4;
      border-radius: 8px !important;
      padding: 8px 16px !important;
    }

    .ant-collapse-extra {
      margin-left: auto;
    }

    .ant-collapse-item-active > .ant-collapse-header {
      background-color: @gray-4;
      border-radius: 8px 8px 0 0 !important;
    }

    .ant-collapse-content-box {
      font-weight: bold;
    }

    .ant-collapse-content-box ul {
      font-weight: initial;
      padding-left: 20px;
    }

    .ant-collapse-item {
      margin-bottom: 24px;
      border: 1px solid #f0f0f0 !important;
      border-radius: 8px !important;
      box-sizing: border-box;
    }

    .ant-collapse-content-box ul li::marker {
      font-size: 8px;
    }

    .ant-collapse-content-box ul > li {
      line-height: 2.5;
    }

    .anticon-edit > svg, .anticon-delete > svg {
      font-size: 16px;
      color: @gray-7;
    }

    .anticon-delete {
      margin-left: 12px;
    }
  }
}

.proceedSummary {
  :global {
    h3.ant-typography, .ant-typography h3 {
     margin-bottom: 1.2em;
    }
    .ant-table-thead > tr > th {
      font-weight: bold;
    }
  }
}

.impactIndicators {
  list-style-position: inside;
  list-style-type: disc !important;
  margin-left: 10px;
}

.impactIndicators > li::marker {
  font-size: 12px !important;
}

.excludedCategory {
  margin-top: 40px;
  :global {
    font-size: 16px;
  }
}

.selectCategory div span {
  font-size: 16px !important;
}

.tableProceed {
  margin-bottom: 40px;
  :global {
    .ant-table, .ant-table-body, .ant-table-cell {
      background-color: transparent !important;
    }

    thead[class*="ant-table-thead"] th {
      padding: 20px 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
    }

    .ant-table-cell {
      padding: 20px 10px;
      vertical-align: top !important;
    }

    .ant-table-thead {
      font-weight: bold;
    }
  }
}

.sdgs {
  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    width: 18px;
  }
}

.subtitle {
  font-weight: 600 !important;
  margin-bottom: 20px;
}
