@import "../../styles/theme.less";

.step {
  padding: 0 0 9px;

  :global {
    .ant-steps-item-description {
      padding-bottom: 0 !important;
    }
    .ant-steps-item-icon{
      margin-right: 8px !important;
    }
    .ant-steps-item-container {
      ::after {
        content: none !important;
      }
    }

    .ant-steps-item-title {
      white-space: normal !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      padding: 0;
    }
    .ant-steps-item-content {
      padding-top: 5px;

      .ant-steps-item-title {
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 21px !important;
      }
    }
  }
}
.current{
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #E5E5E6;
  background-color: #582090;
}

.currentText{
  color: #fff !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.next{
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #E5E5E6;
  background-color: #fff;
}

.nextText{
  color: #595959;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.done{
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid @lime-6;
  background-color: #fff
}

.doneText {
  display: flex;
  color: @lime-6 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

}

.stepsBlock {
  margin: 50px auto 0 !important;
  padding: 20px !important;
  border: 1px solid #E5E5E6;
  box-sizing: border-box;
  border-radius: 10px;
  width: 270px !important;
}

.progress {
  margin-top: 5px;
  line-height: 20.5px !important;

  &__title {
    line-height: 20.5px !important;

    &.percent {
      margin-left: 6px;
    }
  }
  &__inner {
    display: flex;
  }
}

.progressTitle {
  font-size: 16px;
  line-height: 130%;
  color: #8C8C8C !important;
}