@import "~antd/dist/antd.less";
@import "../antd-ovveride/style";
@import "./theme.less";

#root {
  min-height: 100%;
}

html,
body {
  &.-overflow {
    overflow: hidden;
  }
}

:root {
  each(.activitiestypes(), {
      --activity-type-@{key}: @value;
  });
}

.fade {
  &-enter,
  &-appear {
    opacity: 0;
  }
  &-enter-active,
  &-appear-active {
    opacity: 1;
    transition: opacity 0.3s;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 0.3s;
  }
}
