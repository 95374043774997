.container {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.header {
  flex-shrink: 0;
  flex-grow: 0;
}
