.container {
  &:last-child .container__content{
    padding-bottom: 0;
  }

  &--without_title {
    &__content {
      padding: 0 !important;
    }
  }

  &__content {
    margin-left: 40px;
    padding: 20px 0;

    &--one {
      padding: 0;
    }
  }


}