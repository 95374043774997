@import "../../../styles/theme.less";

@defaultWinWidth: 812;
.toVw(@property, @pxValue, @minPxValue) {
  @responsiveValue: 1vw * (@pxValue / @defaultWinWidth * 100);
  --pxValue: @pxValue;
  --minPxValue: @minPxValue;
  --responsiveValue: @responsiveValue;
  @{property}: ~"max(min(var(--pxValue), var(--responsiveValue)), var(--minPxValue))";
}

.logo {
  color: @blue-5;
  .toVw(margin-bottom, 40px, 20px);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  min-height: 100vh;
}

.notFoundIcon {
  .toVw(margin-right, 100px, 30px);
  svg {
    .toVw(width, 254px, 200px);
    height: auto;
  }
}
.textBlock {
  display: block;
  .toVw(margin-bottom, 60px, 20px);
}
