@import "../../styles/theme.less";

.cardHeader {
  --footerHeight: 48px;
  position: relative;
}
.image {
  display: block;
  width: 100%;
  height: 165px;
  object-fit: cover;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(-1 * var(--footerHeight) / 2);
  position: relative;
}

.logo {
  width: var(--footerHeight);
  height: var(--footerHeight);
  object-fit: contain;
  background-color: var(--cardBg);
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid @gray-5;
  box-sizing: border-box;
  padding: 7px;
}

.sdgsList {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--cardBg);
  border: 3px solid var(--cardBg);
  border-radius: 4px;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Ik02IDYuMDU3IDAgNmE2IDYgMCAwIDAgNi02djYuMDU3WiIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-5px);
  }

  &::before {
    left: -8px;
  }

  &::after {
    right: -8px;
    transform: translateY(-5px) scaleX(-1);
  }
}
