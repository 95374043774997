@import "../../styles/theme";

.colorizeBtn(@color, @backgroundColor) {
  &:global(:not(.evercity-ui-radio-button-wrapper-disabled)) {
    &:hover {
      color: @gray-8 !important;
    }
    &:global(.evercity-ui-radio-button-wrapper-checked) {
      color: @color !important;
      border-color: @backgroundColor !important;
      background-color: @backgroundColor;
      box-shadow: none !important;
      &:before {
        background-color: @backgroundColor !important;
      }
    }
  }
}

.errorMessageAbsolute {
  :global {
    .evercity-ui-form-item-explain {
      position: absolute !important;
      top: 100%;
      left: 0;
    }
  }
}

.radioButton {
  &--success {
    .colorizeBtn(white, @lime-6);
  }
  &--rejected {
    .colorizeBtn(white, @red-4);
  }
  &--neutral {
    .colorizeBtn(@gray-9, @gray-5);
  }
}
