.remainedWidthColumn {
  width: 100%;
  max-width: 25%;
  padding-right: 30px !important;
  th& {
    padding-left: 32px !important;
  }
  td& {
    padding-left: 10px !important;
  }
}
.colorColumn {
  padding-right: 5px !important;
}
