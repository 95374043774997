@import "../../styles/theme.less";

.grid {
  display: grid;
  grid-gap: @grid-gap;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-template-areas:
    "activityTypes substantialContribution taxonomyAlignment"
    "activities activities activities";
}

.activityTypes {
  grid-area: activityTypes;
}

.substantialContribution {
  grid-area: substantialContribution;
}

.taxonomyAlignment {
  grid-area: taxonomyAlignment;
}

.activities {
  grid-area: activities;
}