@import "../../styles/theme.less";

.container {
  position: relative;
  overflow-y: auto;
  height: 100%;
}

.content {
  padding: 30px 28px 22px;
}

.title {
  color: @text-color-inverse !important;
}

.text {
  .max-line(3);
  color: @text-color-inverse !important;
}

.itemValue {
  color: @gray-1 !important;
}

.description {
  margin: 10px 0 8px;
  composes: text;
}

.preview {
  display: block;
  width: 100%;
  height: 220px;
}

.tags {
  margin-top: 30px;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  font-size: 24px;
  color: @gray-1 !important;
  cursor: pointer;

  @media @breakpoint-mobile-max {
    position: fixed;
    top: 80px;
  }
}

.button {
  margin-top: 22px;
  width: 100%;
}

.listItem {
  display: block !important;
}
