@import "../../styles/theme.less";

.inputNumber {
  &:global(.ant-input-number) {
    width: 100% !important;
    border-color: @input-border-color;
    &:hover,
    &:focus,
    &-focused {
      .ant-input-number-handler {
        border-color: @input-number-hover-border-color !important;
      }
    }
  }
  &--disableControls {
    :global {
      .ant-input-number-handler-wrap {
        display: none !important;
      }
    }
  }
}
