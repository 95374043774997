@import "../../styles/theme.less";

.upload {
  display: flex;
  flex-direction: column;
  :global {
    .ant-upload-list-text {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      order: -1;
      transition: margin-bottom @animation-duration-base @ease-base-out;
      margin-bottom: 15px;
      &:before {
        content: none;
      }
      // & > *:last-child {
      //   margin-bottom: 15px;
      // }
    }
    .ant-upload-list-text-container {
      max-width: 200px !important;
    }
    .ant-upload-list-item {
      height: 26px !important;
      margin-top: 0 !important;

      &-info {
        background-color: @gray-4;
        color: @blue-6;
        border-radius: 5px !important;
        padding-left: 8px !important;
      }
      &-error {
        .ant-upload-text-icon svg {
          color: @red-6 !important;
        }
      }
    }
    .ant-upload-text-icon svg {
      color: @blue-6;
    }
    .ant-upload-list-item-card-actions-btn {
      opacity: 1 !important;
      &,
      &:hover {
        background-color: transparent !important;
      }
      svg {
        color: @gray-7;
        transition: color @animation-duration-base @ease-base-out;
        &:hover {
          color: @gray-10;
        }
      }
    }
    .ant-upload-list-item-name {
      padding-right: 2px !important;
    }
    .ant-upload-list-item-progress {
      display: none !important;
    }
  }
  &--empty {
    :global {
      .ant-upload-list-text {
        margin-bottom: 0;
      }
    }
  }
}
