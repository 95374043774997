@import "../../styles/theme.less";

.header {
  text-align: center;
  display: block;
}
.listingProgress {
  width: 100%;
  :global {
    .ant-progress-bg {
      background-color: @green-6 !important;
    }
    .ant-progress-text {
      color: @gray-7 !important;
    }
  }
}
