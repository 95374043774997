@import "../../styles/theme.less";

.layerControllers {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -14px;
    left: 0;
    width: calc(100% + 10px);
    height: calc(100% + 14px);
    background-color: @gray-3;
    border-radius: 5px;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
  padding-left: 17px;
  & > * {
    z-index: 1;
  }
  &--checked {
    &:before {
      opacity: 1;
    }
  }
}

.layerSwitcher {
  line-height: 0;
}

.icon {
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: @gray-10;
  transition: color @animation-duration-base @ease-base-out;
  &[disabled] {
    color: @disabled-color;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}

.filtersCollapse {
  position: relative;
  :global {
    .ant-collapse-header {
      display: none !important;
    }
    .ant-collapse-content-box {
      padding: 25px 0 9px !important;
    }
  }
}

.layerSwitch {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 10;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
  }
}
