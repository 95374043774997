@import "../../styles/theme.less";

.drawer {
  padding-bottom: 0 !important;

  &__title {
    font-size: 26px;
    line-height: 28.6px;
    font-weight: @font-weight-bold;

    color: @black;
  }
}

.submitBtn {
  display: flex !important;
  align-items: center;
  flex-wrap: nowrap;
  &:hover .submitCountIcon {
    color: color(~`colorPalette("@{primary-color}", 5) `);
  }
  &:active .submitCountIcon {
    color: color(~`colorPalette("@{primary-color}", 7) `);
  }
  & > * {
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }
  }
}

.submitCountIcon {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: @primary-color;
  text-shadow: none;
  background-color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  // &:after{
  //   content: "";
  //   display: block;

  // }
}
