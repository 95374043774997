.formItem {
  margin-bottom: 0 !important;
  :global {
    .ant-form-item-label {
      white-space: nowrap;
      overflow: visible;
    }
  }
}

.submitWrapper {
  margin-top: 40px;
  display: flex;
}
