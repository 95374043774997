@import "../../styles/theme";

.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.actionsWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  color: @gray-1;
  cursor: pointer;
  svg {
    width: 167px;
    height: 20px;
  }

  &--disabled {
    pointer-events: none;
    cursor: default;
  }
}

.bellIcon {
  && {
    color: white;
    font-size: 24px;
    transition: color @animation-duration-base @ease-base-out;
    cursor: pointer;
    outline: none;
    &:hover {
      color: fade(white, 85%);
    }
  }
}

.team {
  &__link {
    display: flex;
  }
  &__icon {
    && {
      color: white;
      font-size: 24px;
      transition: color @animation-duration-base @ease-base-out;
      cursor: pointer;
      outline: none;

      &:hover {
        color: fade(white, 85%);
      }
    }
  }
}

.avatar {
  && {
    cursor: pointer;
    transition: opacity @animation-duration-base @ease-base-out;
    &:hover {
      opacity: 0.85;
    }
  }
}

.getDemo {
  color: @blue-6 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.login {
  transition: opacity @animation-duration-base @ease-base-out;
  outline: none;

  &:hover {
    opacity: 0.8;
  }

  @media @breakpoint-mobile-max {
    display: none;
  }
}

.loginIcon {
   && {
    color: white;
    font-size: 18px;
    vertical-align: middle;
  }
}

.loginLabel
{
  font-size: 14px;
  line-height: 24px;
  color: @gray-1!important;
  vertical-align: middle;
  position: relative;
  top: 1px;
}

