@import "../../styles/theme.less";

.bg-block(@color) {
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: @color;
  }
}

.theme-1 {
  .bg-block(rgba(@red-2, 0.2));
}
.theme-2 {
  .bg-block(rgba(@gold-2, 0.3));
}
