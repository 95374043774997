@import "../../../styles/theme.less";

.pointsLegend {
  display: flex;
  column-gap: min(80px, 9%);
  row-gap: 3px;
  align-items: center;
  flex-wrap: wrap;
  @media @breakpoint-mobile-max {
    justify-content: center;
    column-gap: 60px;
    row-gap: 30px;
  }
}

.pointsLegendItem {
  white-space: nowrap;
}

.pointsLegendPoint {
  width: 20px;
  height: 20px;
  background-color: currentColor;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pointsLegendIcon {
  color: #fff;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
  }
}

.infoIcon {
  color: @gray-6 !important;
  width: 12px;
  height: 12px;
  svg {
    width: 100%;
    height: 100%;
  }
}
