@import "../../styles/theme.less";

//TODO: сделать миксин для установки ширины и отступов
.drawer {
  color: red;
  &--size {
    // &-default {}
    &-medium {
      &:global(.ant-drawer-right:not(.no-mask)),
      &:global(.ant-drawer-left:not(.no-mask)) {
        :global {
          .ant-drawer-content-wrapper {
            width: 580px !important;
          }
        }
      }
      &:global(.no-mask.ant-drawer-right),
      &:global(.no-mask.ant-drawer-left) {
        width: 580px !important;
      }

      &:global(.ant-drawer-right),
      &:global(.ant-drawer-left) {
        @horizontalPaddingLeft: 40px;
        @horizontalPaddingRight: 64px;
        :global {
          .ant-drawer-header {
            padding: 20px @horizontalPaddingRight 30px @horizontalPaddingLeft !important;
          }
          .ant-drawer-body {
            padding: 0 @horizontalPaddingRight 0 @horizontalPaddingLeft !important;
          }
          .ant-drawer-footer {
            padding: 22px @horizontalPaddingRight 22px @horizontalPaddingLeft !important;
          }
        }
      }
    }
    // &-large {}
  }

  :global {
    .ant-drawer-title {
      font-size: 26px !important;
      margin-bottom: 0 !important;
    }
    .ant-drawer-header {
      border-radius: 0 !important;
      border: none !important;
    }
    .ant-drawer-close {
      top: 6px !important;
      &:hover svg {
        color: @gray-7;
      }
      &:active svg {
        color: @gray-9;
      }
      svg {
        color: @gray-8;
        font-size: 24px;
      }
    }
  }
  &:global(.ant-drawer-right),
  &:global(.ant-drawer-left) {
    top: @layout-header-height !important;
    height: calc(100vh - @layout-header-height) !important;
  }
}
