@import "../../../styles/theme.less";

@defaultWinHeight: 812;
.toVh(@property, @pxValue, @minPxValue) {
  @responsiveValue: 1vh * (@pxValue / @defaultWinHeight * 100);
  --pxValue: @pxValue;
  --minPxValue: @minPxValue;
  --responsiveValue: @responsiveValue;
  @{property}: ~"max(min(var(--pxValue), var(--responsiveValue)), var(--minPxValue))";
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .toVh(padding-top, 100px, 40px);
  .toVh(padding-bottom, 50px, 20px);
}

.landscapeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  color: @blue-5;
}

.textBlock {
  text-align: center;
  .toVh(margin-bottom, 56px, 10px) !important;
}
.notFoundIcon {
  .toVh(margin-bottom, 50px, 20px);
  .toVh(margin-top, 50px, 10px);
  svg {
    width: auto;
    height: 293px;
    .toVh(height, 293px, 200px);
  }
}
