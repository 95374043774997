@import "../../styles/theme.less";

.title,
.link {
  .max-line(1);
}

.title {
  &--project {
    .max-line(2);
    min-height: 56px;
  }

  &--commodity {
    .max-line(2);
    min-height: 56px;
  }
}

.description {
  .max-line(2);
  min-height: 3.3em;

  &--project {
    .max-line(4);
    min-height: 88px;
  }
}
