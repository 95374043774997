@import "../../styles/theme.less";

.categoriesSwitcher {
  width: 100%;
  display: flex !important;
  justify-content: center;
  background-color: #e8e8e8;
  padding: 3px 4px !important;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: nowrap;
  gap: 3px !important;
}
.radioBtn {
  width: 100%;
  flex-shrink: 1;
  display: flex !important;
  justify-content: center;
  background: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  transition: background-color, color @animation-duration-base @ease-base-out;
  &:not(:first-child)::before {
    display: none !important;
  }
  &:global(.ant-radio-button-wrapper-checked),
  &:hover {
    background-color: @gray-3 !important;
    color: @gray-8 !important;
  }
}
