@import "../../styles/theme.less";

.sdgItem {
  --size: 20px;
  width: var(--size);
  height: var(--size);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
}

.sdgTooltip {
  display: flex;
  align-items: center;
  gap: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: white;
  max-width: 171px;

  img {
    --size: 32px;
    width: var(--size);
    height: var(--size);
  }
}

.icon {
  border-radius: 3px;
}
