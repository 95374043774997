@import "../../styles/theme.less";

.title {
  font-size: 30px;
  line-height: 38px;

  @media @breakpoint-mobile-max {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

.mainImage {
  display: block;
  width: 100%;
  height: 302px;
  object-fit: cover;
}

.companyLogo {
  display: block;
  width: 64px;
  height: 64px;
  object-fit: contain;
  overflow: hidden;
  border: 1px solid @gray-5;
  border-radius: 6px;
  padding: 7px;
}
.countryLink {
  cursor: pointer;
  color: @text-color-secondary !important;
  transition: color @animation-duration-base @ease-base-out;
  &:hover {
    color: color(~`colorPalette("@{text-color-secondary}", 5) `) !important;
  }
  &:active {
    color: color(~`colorPalette("@{text-color-secondary}", 7) `) !important;
  }
}
