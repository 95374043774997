@import "../../styles/theme.less";

.alignedStatus {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: @lime-6;
  margin-left: 5px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 5px);
    height: calc(100% + 5px);
  }
}
