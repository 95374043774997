@import "../../styles/theme.less";
@import "point.less";

.children {
  padding-left: 30px;
  margin-top: 30px;
}
.formItem {
  :global {
    .ant-form-item-control-input {
      display: none !important;
    }
    .ant-form-item-explain {
      position: absolute !important;
      top: 0;
      left: 0;
    }
  }
}
