@import "../../styles/theme.less";

.table {
  th {
    font-size: 14px !important;
    line-height: 22px !important;
    background: @gray-3 !important;
    font-weight: 600 !important;
    padding: 6px 31px !important;

    &:first-child {
      border-radius: 8px 0 0 0 !important;
    }

    &:last-child {
      border-radius: 0 8px 0 0 !important;
    }
  }

  td {
    padding-left: 31px !important;

    &:first-child {
      border-left: 1px solid @gray-4;
      padding-left: 10px !important;
    }

    &:last-child {
      border-right: 1px solid @gray-4;
    }
  }

  tr {
    &:last-child {
      td {
        &:first-child {
          border-radius: 0 0 0 8px !important;
        }
        &:last-child {
          border-radius: 0 0 8px 0 !important;
        }
      }
    }
  }
}