@import "../../styles/theme.less";

.container {
  color: red;
  :global {
    .ant-modal {
      width: 600px !important;
    }
    .ant-modal-content {
      border-radius: @border-radius !important;
      overflow: hidden;
    }
    .ant-modal-header {
      padding: 60px 60px 30px 60px !important;
      border-bottom: 0 !important;
    }
    .ant-modal-body {
      padding: 0 60px !important;
    }
    .ant-modal-footer {
      padding: 40px 60px 60px 60px !important;
      border-top: 0 !important;
      display: flex;
      justify-content: flex-start;
    }
  }
}

.headerContent {
  display: flex;
  align-items: center;
  svg {
    font-size: 38px;
    margin-right: 12px;
    // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}
.message {
  color: @gray-7 !important;
}
