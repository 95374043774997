@import "../../styles/theme.less";

.fullMapTriggerButton {
  border-radius: 6px;
  background-color: @gray-1;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  width: 291px;
  transition: background-color @animation-duration-base @ease-base-out;
  @media @breakpoint-mobile-max {
    width: 180px;
  }
  &:hover {
    background-color: @gray-2;
  }
  &:active {
    background-color: @gray-3;
  }
}

.icon {
  font-size: 12px;
  padding: 6px;
  border: 1px solid @text-color-secondary;
  border-radius: 4px;
  box-sizing: border-box;
}
