@import "../../styles/theme.less";

.goals
{
  display: grid !important;
  margin-top: 30px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 13px;

  :global {
    .ant-checkbox {
      position: relative;
      left: -9999px;
      z-index: -100;
      opacity: 0;
      display: none !important;
    }

    .ant-checkbox-wrapper {
      padding: 0 !important;
      margin-left: 0 !important;

      span {
        padding: 0 !important;
      }

      &.ant-checkbox-wrapper-checked {
        background-color: currentColor;

        svg {
          path[fill] {
            fill: @gray-1;
          }
          path[stroke] {
            stroke: @gray-1;
          }
        }
      }
    }
  }
}

.goal {
  --size: 50px;

  margin-left: 0 !important;
  width: var(--size);
  height: var(--size);
  display: block;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}
