@import "../../styles/theme.less";

.grid {
  --size: 12px;

  display: grid;
  grid-column-gap: var(--size);
  grid-template-columns: var(--size) 1fr;
}

.marker {
  --size: 12px;

  border-radius: 50%;
  display: inline-block;
  height: var(--size);
  overflow: hidden;
  position: relative;
  top: 5px;
  width: var(--size);
}

.text {
  padding-right: 32px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.text--normal {
  font-size: @font-size-base;
}

.text--large {
  font-size: @font-size-lg !important;
}
