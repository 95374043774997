@import "../../styles/theme.less";

.text {
  font-size: @font-size-base;
  line-height: @line-height-base;

  &--noMargin {
    margin-bottom: 0 !important;
  }
  &--block {
    display: block;
  }
  &--size-extra-large {
    font-size: @font-size-lg + 2;
    line-height: @line-height-base;
  }
  &--size-large {
    font-size: @font-size-lg;
    line-height: @line-height-base;
  }
  &--size-small {
    font-size: @font-size-base - 2;
    line-height: @line-height-base;
  }
  &--size-extra-small {
    font-size: @font-size-base - 4;
    line-height: @line-height-base;
  }

  &--color-primary {
    color: @blue-6 !important;
  }

  &--color-secondary {
    color: @gray-7-5 !important;
  }

  &--color-caption {
    color: @gray-7 !important;
  }

  &--color-gray {
    color: @gray-6 !important;
  }

  &--color-success {
    color: @success-color !important;
  }
  &--capitalize {
    text-transform: capitalize;
  }
}
