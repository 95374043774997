@import "../../styles/theme.less";

.tooltip {
  background-color: @gray-1;
  border-radius: @border-radius;
  box-shadow: @box-shadow-base;
  min-width: 300px;
  padding: 16px 18px;
  position: relative;
  z-index: 2000;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listReverse {
  flex-direction: column-reverse;
}

.listItem {
  display: grid;
  grid-template-columns: 12px 1fr;
  margin: none;
  grid-column-gap: 12px;
}

.row {
  padding: 10px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #E5E5E6;
  }
}

.subrow {
  padding-top: 6px;
}

.title {
  font-size: @font-size-lg;
  font-weight: @font-weight-bold;
  position: relative;
  top: -2px;

  &::first-letter {
    text-transform: capitalize;
  }
}

.label {
  color: @gray-8;
  font-size: @font-size-base;
  line-height: 22px;
}

.header {
  margin-bottom: 16px;
}

.footer {
  border-top: 1px solid #E5E5E6;
  margin-top: 10px;
  padding-top: 10px;
}
