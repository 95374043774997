@import "../../styles/theme.less";

.tagBase {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 0 6px;
  border-radius: 2px;
  border: 2px solid transparent;
}

.categoryTag {
  background-color: @gray-5;
  color: white;
}

.statusTag {
  color: white;
  background-color: @gray-4;
  &--green {
    background-color: @green-5;
  }
  &--gray1 {
    background-color: @gray-7;
  }
  &--gray2 {
    background-color: @gray-6;
  }
  &--blue {
    background-color: @geekblue-6;
  }
  &--purple {
    background-color: @purple-5;
  }
  &--red {
    background-color: @red-4;
  }
  &--gray8 {
    background-color: @gray-8;
  }
  &--gold {
    background-color: @gold-5;
  }
  &--cyan {
    background-color: @cyan-5;
  }
}

.assetTypeTag {
  border: 2px solid currentColor;
  box-sizing: border-box;
  &--orange {
    color: @orange-6;
  }
  &--lime {
    color: @lime-7;
  }
  &--blue {
    color: @blue-4;
  }
  &--red {
    color: @red-5;
  }
  &--yellow {
    color: @yellow-6;
  }
  &--geekblue {
    color: @geekblue-6;
  }
  &--magenta {
    color: @magenta-6;
  }
  &--cyan {
    color: @cyan-6;
  }
  &--gray {
    color: @gray-8;
  }
  &--purple {
    color: @purple-5;
  }
}
