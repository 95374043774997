@import "../../../styles/theme";


.head {
  margin-bottom: 8px;
}

.title {
  font-size: 30px;
  line-height: 38px;
}

.tag {
  border-radius: 31px;
  padding: 4px 12px;

  background-color: @blue-1;
  color: @purple-6;
}

.description {
  width: 280px;
  color: @gray-9;

  margin-bottom: 20px;
}

.btn {
  width: 103px;

  margin-bottom: 24px;
}

.marker {
  background-color: @blue-1;

  color: @blue-6;

  overflow: hidden;
  border-radius: 50%;
}

.list {
  max-width: 280px;
}

.list, .description {
  color: @gray-8;
}

.radio {
  cursor: inherit;
}
