@import "../../../styles/theme.less";

.dots {
  color: @gray-6;
  cursor: pointer;
  transition: color @animation-duration-base @ease-base-out;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  &:hover {
    color: @gray-5;
  }
  &:active {
    color: @gray-7;
  }
}
