@import "../../styles/theme.less";

.collapse {
  padding: 0;
  min-height: 1px !important;
  :global {
    .ant-collapse-header {
      display: none !important;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
}

.container {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: @blue-1;
  color: @gray-9;
  border-radius: 10px;
}

.icon {
  color: @primary-color;
  font-size: 24px;
  margin-right: 16px;
  display: flex;
  align-items: flex-start;
  // padding-top: 2px;
  margin-top: -1px;
}
.content {
  width: 100%;
  flex-shrink: 1;
}
.close {
  font-size: 14px;
  color: @gray-7;
  transition: color @animation-duration-base @ease-base-out;
  cursor: pointer;
  margin-left: 15px;
  &:hover {
    color: @gray-8;
  }
  &:active {
    color: @gray-9;
  }
}
