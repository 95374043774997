@import "../../styles/theme.less";

.table {
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  :global(.ant-table-thead) {
    background-color: @gray-4;
    font-size: 12px;
    line-height: 20px;
    font-weight: @font-weight-bold;
  }

  :global(.ant-table-tbody) {
    --border: @gray-5 1px solid;
    --radius: 10px;

    font-size: 12px;

    & > tr {
      & > td {
        &:first-of-type {
          border-left: var(--border);
        }

        &:last-of-type {
          border-right: var(--border);
        }
      }

      &:last-of-type {
        & > td {
          border-bottom: var(--border);

          &:first-of-type {
            border-radius: 0 0 0 var(--radius);
          }

          &:last-of-type {
            border-radius: 0 0 var(--radius) 0;
          }
        }
      }
    }
  };
}

.title
{
  font-size: 14px;
  line-height: 22px;
}

.gray
{
  font-size: 14px;
  line-height: 22px;
  color: @gray-7;
}






