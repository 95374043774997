@import "../../styles/theme.less";

.container {
  border-radius: 4px;
  position: relative;
}

.blur {
  filter: blur(5px);
}

.overlay
{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(@lock-overlay-color, 0.5);
  gap: 15px;
}

.icon
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  font-size: 18px;
  color: @gray-1;
  background-color: @blue-4;
  border: 2px solid @blue-4;
  border-radius: 8px;
}


