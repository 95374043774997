@import "../../styles/theme.less";

.summaryButton{
  height: auto !important;
  padding: 0 !important;
  outline: none;
  border: none !important;
  cursor: pointer;
  border-bottom: 1px solid @blue-2 !important;
  color: @blue-6 !important;
  background-color: transparent !important;

  &:hover {
    background-color: transparent;
  }

  &--disabled {
    pointer-events: none;
    color: @gray-5 !important;
    border: none !important;
  }
}

.actions {
  width: 215px;
  border-radius: 8px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));

  li {
      padding: 9px 12px;
  }

  &__delete {
    color: @error-color !important;
  }

  &--bordered {
    border-bottom: 1px solid @gray-4;
  }

  .item {
    &__text {
      color: inherit;
      padding-left: 20px;
    }
  }
}