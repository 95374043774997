@import "../../styles/theme.less";

.mapAside {
  padding: 15px 23px 50px 23px;
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  @media @breakpoint-mobile-max {
    width: 100% !important;
  }
}
.backBtn {
  margin-left: -16px;
}
.mobileCloseBtn {
  @media @breakpoint-mobile {
    display: none !important;
  }
}
.nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
