@import "../../styles/theme.less";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  &:not([disabled]) {
    cursor: pointer;
    transition: background-color @animation-duration-base @ease-base-out;
    &:hover {
      background-color: @blue-1;
    }
  }
}
.leftPart {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  overflow: hidden;
  margin-right: 10px;
  width: 100%;
}
.rightPart {
  flex-shrink: 0;
}

.fileIcon {
  font-size: 22px;
  margin-left: 10px;
  margin-right: 8px;
}
.fileName {
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid transparent;
  width: 100%;
  &:global(.ant-typography-edit-content) {
    margin: 0 !important;
    left: -5px !important;
    padding-left: 5px !important;
    border-color: @blue-6;
    border-radius: 6px;
  }
  :global .ant-typography-edit-content-confirm {
    display: none !important;
  }
  textarea {
    position: relative;
    top: 0.8em !important;
    resize: none !important;
    height: auto !important;
    line-height: 1 !important;
    padding: 0 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    font-size: inherit !important;
  }
}
.errorIcon {
  font-size: 14px;
  color: @red-6;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: @blue-4;
  // transition: opacity @animation-duration-base @ease-base-out;
}

.actionsDots {
  transition: opacity @animation-duration-base @ease-base-out,
    visibility @animation-duration-base @ease-base-out;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.actionsWrapper {
  position: relative;
}
