.remainedWidthColumn {
  width: 100%;
  max-width: 25%;
  padding-right: 30px !important;
  th& {
    padding-left: 43px !important;
  }
  td& {
    padding-left: 16px !important;
  }
}
.unitRow,
.subunitRow > td {
  cursor: pointer;
}
