@import "../../styles/theme.less";

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  height: 100vh !important;
  &:before,
  &:after {
    content: "";
    display: block;
    height: 100vh;
    min-height: 50px;
    width: 100%;
    flex-shrink: 1;
  }
  :global {
    .ant-modal {
      flex-shrink: 0;
      width: auto !important;
      top: 0;
      padding-bottom: 0 !important;
    }
    .ant-modal-content {
      flex-shrink: 1;
      width: 600px !important;
      box-sizing: border-box;
      border-radius: 10px !important;
    }
    .ant-modal-body {
      padding: 40px 60px 0 60px !important;
      text-align: center;
    }
    .ant-modal-footer {
      border-top: none !important;
      display: flex;
      justify-content: center;
      padding-bottom: 28px !important;
      padding-top: 28px !important;
    }
  }
  &--noFooter {
    :global {
      .ant-modal-body {
        padding-bottom: 40px !important;
      }
    }
  }
}
.description {
  color: @gray-7 !important;
  margin-top: 10px;
  margin-bottom: 20px !important;
  display: block;
}
.player {
  position: relative;
  width: 100% !important;
  padding-top: 56.25%;
  border-radius: 10px !important;
  overflow: hidden;
  background-color: black;
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
.closeBtn {
  cursor: pointer;
  color: @gray-9;
  transition: color @animation-duration-base @ease-base-out;
  &:hover {
    color: @gray-8;
  }
  &:active {
    color: @gray-9;
  }
}
