@import "../../styles/theme.less";

.modal {
  :global {
    .ant-modal-content, .ant-modal-header {
      border-radius: @border-radius;
    }

    .ant-modal-header, .ant-modal-body {
      padding-right: 63px;
      padding-left: 82px;
    }

    .ant-modal-header {
      padding-top: 48px;
      padding-bottom: 14px;
    }

    .ant-modal-body {
      padding-top: 0;
      min-height: 560px;
      overflow: hidden !important;
    }

    .ant-modal-footer {
      padding: 13px 82px 13px 82px ;
      border-radius: 0 0 @border-radius @border-radius;
      height: 66px;
      background-color: @blue-1;
      display: flex;
    }
  }

  &--auto_height {
    :global {
      .ant-modal-body {
        min-height: 0;
      }
    }
  }

  &__close_icon {
    position: absolute;
    top: 23px;
    right: 23px;
    font-size: 21px;
  }
}