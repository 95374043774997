@import "../../styles/theme.less";

.container {
  margin-top: 30px;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10;
}

.scrolledContainer {
  overflow: auto;
}
.filteredList {
  padding-top: 15px;
}

.selectedActivitiesList {
  padding-top: 15px;
}
