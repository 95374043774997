@import "../../styles/theme.less";

.cardLayout {
  --cardBg: @gray-1;
  --cardHorizontalOffset: 25px;
  position: relative;
  border: 1px solid @gray-5;
  border-radius: 2px;
  box-sizing: border-box;
  min-height: 490px;
  max-width: 340px;
  background-color: var(--cardBg);
  display: flex;
  &--responsive {
    width: auto;
  }
}
