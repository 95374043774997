@import "../../../styles/theme.less";

.rows {
  display: grid;
  grid-gap: @grid-gap;
  max-width: 100%;
  min-width: 700px;
  grid-template-columns: calc(50% - @grid-gap / 2) calc(50% - @grid-gap / 2);
  grid-template-rows: auto;

  grid-template-areas:
    "company company"
    "taxonomy taxonomy"
    "info info"
    "overview overview";
}

.grid {
  grid-template-areas:
    "company taxonomy"
    "company taxonomy"
    "info taxonomy"
    "overview overview";
}

.company {
  grid-area: company;
}

.taxonomy {
  grid-area: taxonomy;
}

.info {
  grid-area: info;
}

.overview {
  grid-area: overview;
}
