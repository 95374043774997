.codesTree {
  :global {
    .ant-tree-treenode {
      margin-bottom: 10px !important;
    }
    .ant-tree-node-content-wrapper {
      cursor: default !important;
    }
  }
}
.divisionTitle {
  margin-bottom: 0 !important;
}
