@import "../../../styles/theme.less";

.actions {
  margin-top: 64px;
}

.goal {
  & svg {
    width: 40px;
    height: 40px;
  }
}

.drawer {
  :global {
    & .evercity-ui-drawer-body {
      padding: 24px 60px 6px 44px !important;
    }

    & .evercity-ui-drawer-wrapper-body {
      position: relative;
    }

    & .evercity-ui-drawer-footer {
      padding: 8px 60px 24px 44px !important;
    }

    & .evercity-ui-form-item {
      margin: 0;
    }
  }

  &__label {
    font-size: 20px;
    font-weight: 600;
  }

  &__info-icon {
    font-size: 12px;
  }

  &__description {
    display: block;
    margin: 16px 0 8px;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }

  &__textarea {
    :global {
      .evercity-ui-input {
        padding-bottom: 14px;
      }
    }
  }

  &__search-container {
    border-bottom: 2px solid @blue-new;
    margin-bottom: 16px !important;

    :global {
      & .evercity-ui-input-affix-wrapper {
        padding-left: 0;
      }
    }
  }

  &__select {
    :global {
      .evercity-ui-select-selector {
        height: 48px !important;
      }
    }
  }

  .drawer__close-icon {
    position: absolute;
    right: 22px;
    top: 26px;

    & svg {
      width: 21px;
      height: 21px;
    }
  }
}

.table {
  :global {
    & td {
      vertical-align: top;
    }
  }
}

.target {
  overflow: hidden;
  & * {
    text-overflow: ellipsis;
  }
  &__popup {
    & &-text {
      white-space: normal;
    }
  }
}

.indicator {
  &__title {
    :global {
      font-size: 16px !important;
      line-height: 24px !important;
      margin: 0 !important;
    }
  }

  &__text {
    color: #8c8c8c;
    &--compact {
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__more {
    font-weight: 600;
    font-size: 16px;
    transform: translate(-17px);
  }

  &__actions {
    margin-top: 8px;
  }
}
