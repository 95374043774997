.index {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e5e5e6;
  border-radius: 50%;
}
.noMargin {
  margin: 0 !important;
}
