@import "../../styles/theme.less";

.counter {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  :global {
    .ant-input-number {
      width: 115px;
      line-height: 30px;
      border: 1px solid @gray-5;
    }

    .ant-input-number-handler-wrap {
      opacity: 1;
    }

    .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
      border-left: none;
    }

    .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler-down {
      border-top: none
    }

    .ant-input-number-handler-up:hover, .ant-input-number-handler-down:hover {
      height: 50% !important;
    }

    .ant-input-number-handler-wrap:hover .ant-input-number-handler {
      height: 50%;
    }

    .ant-input-number-handler:hover .ant-input-number-handler-up-inner, .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
      color: black;
    }

    .ant-input-number-handler-up-inner, .ant-input-number-handler-down-inner {
      font-size: 11px !important;
    }

    .ant-input-number-input {
      height: 40px;
    }
  }
}
