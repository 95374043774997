.header {
  margin-bottom: 26px;
}

.nav {
  div[role=tablist] {
    margin-bottom: 40px;
  }
}

.footer {
  margin-top: 40px;
}