.panel {
  margin-bottom: 15px;
  :global {
    .ant-collapse-header {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      padding: 0 !important;
    }
    .anticon {
      margin: 0 !important;
      order: 2;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
      padding-top: 25px !important;
    }
  }
}
.panelTitle {
  order: -1;
  white-space: nowrap;
}
