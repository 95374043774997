.container {
  position: relative;
}

.image {
  transition: opacity .3s ease-in-out .3s;

  &__blurred {
    position: absolute;
    opacity: 0;
  }

  &--preview {
    opacity: 1;
  }
}
