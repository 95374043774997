@import "../../styles/theme.less";

.catalogPage {
  margin-bottom: 80px;
}

.pageHeader {
  padding-right: 0 !important;
  padding-left: 0 !important;

  @media @breakpoint-mobile-max {
    justify-content: center;
    display: flex;
    padding: 50px 0 !important;
  }

  [class~="ant-page-header-heading-left"] {
    @media @breakpoint-mobile-max {
      display: none;
    }
  }
}
