.container {
  margin: 0 auto -20px;
}

.title {
  margin-bottom: 24px!important;
}

.chart {
  height: auto;
  margin: 0 auto;
}
