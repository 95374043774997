@import "../../../styles/theme.less";

.gradientLegend {
  display: grid;
  grid-template-columns: max-content minmax(auto, 490px) max-content;
  column-gap: 10px;
  align-items: center;
  @media @breakpoint-mobile-max {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 5px;
    width: 100%;
  }
}
.boundaryValue {
  @media @breakpoint-mobile-max {
    order: 1;
    flex-shrink: 1;
    width: 50%;
    &--right {
      text-align: right;
    }
  }
}

.lineContainer {
  width: 100%;
  height: 9px;
  @media @breakpoint-mobile-max {
    width: 100%;
    flex-shrink: 0;
    order: 3;
  }
}
.line {
  width: 100%;
  height: 100%;
}
.lineScale {
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  grid-auto-flow: column;
  height: 100%;
  span {
    display: block;
    height: calc(100% + 4px);
    border-right: 1px solid @gray-10;
    &:first-child {
      border-left: 1px solid @gray-10;
    }
  }
}

.lineScaleValues {
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  grid-auto-flow: column;
  justify-items: center;
}
