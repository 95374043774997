@import "../../styles/theme.less";

.productDivider {
  border-bottom: 1px solid @divider-color;

  @media @breakpoint-mobile-max {
    margin-top: 28px !important;
    margin-bottom: 10px !important;
  }

  @media @breakpoint-mobile {
    margin-top: 50px !important;
    margin-bottom: 40px !important;
  }
}
