@import "../../styles/theme.less";

.twoColLayout {
  display: grid;
  grid-row-gap: 40px;
  grid-template-columns: 1fr;

  @media @breakpoint-mobile {
    grid-template-columns: 1fr 320px;
    grid-column-gap: 64px;
  }
}
