.title {
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
  margin-bottom: 20px;
}

.additionalBlock {
  margin-top: 20px;
  background: #FAFAFA;
  border-radius: 8px;

  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 60px;
}

.radioBlock {
  margin-bottom: 40px;
}

.radioBlock:last-child {
  margin-bottom: 30px;
}

.additionalTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}


.block span {
  margin-bottom: 6px;
}

.blockAmountMonth {
  margin-top: 20px;
}

.blockAmountMonth .radioGroup {
  display: flex;
  flex-direction: row;
}

.blockAmountMonth label {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.input {
  height: 40px;
  border: 1px solid #D9D9D9 !important;

  &:focus {
    border: 1px solid #595959 !important;
  }

  &:hover {
    border: 1px solid #595959 !important;
  }
}

.management {
  display: flex;
}

.managementRadio {
  font-size: 16px;
}

.containerCounter {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.managmentProceed {
  :global {
    h3.ant-typography, .ant-typography h3 {
      margin-bottom: 0.8em !important;
    }
    h5.ant-typography, .ant-typography h5 {
      margin-bottom: 0.8em !important;
    }
  }
}

.method {
  :global {
    margin-bottom: 1.2em !important;
    font-size: 16px;
  }
}

.allocation {
  &__counter {
    margin-left: 150px;
  }
}