@import "../../styles/theme.less";


.inputTittle {
  ::placeholder {
    color: @gray-7 !important;
    font-weight: 600 !important;
  }

  input{
    border: none;
    font-weight: 600 !important;
    font-size: 30px !important;
    color: @gray-10 !important;
    line-height: 38px !important;
    margin-right: 15px;
    &:focus-visible{
      outline: none !important;
    }
  }
}

.editIcon{
  color: @gray-7 !important;
  font-size: 18px;
  cursor: pointer;
}

