@import "../../styles/theme.less";

.grid {
  --column-width: 340px;
  display: grid;
  justify-content: flex-start;
  grid-gap: 30px 22px;
  grid-template-columns: repeat(auto-fit, var(--column-width));
  @media @breakpoint-mobile-max {
    grid-template-columns: ~"min(100%, var(--column-width))";
    justify-content: center;
  }
}
