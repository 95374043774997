@import "../../styles/theme.less";

.head {
  margin-bottom: 30px;

  &__button {
    width: 175px;
    height: 40px !important;
  }
}


.summary {
  &__section {
    margin-bottom: 30px;
  }
}