@import "~antd/lib/style/themes/default.less";

//Natural pollete
@gray-1: #ffffff;
@gray-2: #fafafa;
@gray-3: #f5f5f5;
@gray-4: #f0f0f0;
@gray-5: #d9d9d9;
@gray-6: #bfbfbf;
@gray-7: #8c8c8c;
@gray-7-5: #69676f;
@gray-8: #595959;
@gray-9: #434343;
@gray-10: #262626;
@gray-11: #1f1f1f;
@gray-12: #141414;
@gray-13: #000000;

//Blue colors
@blue-1: #efeafb;
@blue-2: #d6aeff;
@blue-3: #af68f6;
@blue-4: #884bd4;
@blue-5: #7333b2;
@blue-6: #582090;
@blue-7: #40116e;
@blue-8: #29074c;
@blue-9: #45008a;
@blue-10: #330066;

@blue-new: #6F13C7;

@black: #000000;

// CUSTOM vars
@controls-border-color: @gray-8;

//Main antd vars
@brand-color: #582090;
@primary-color: @blue-6; // primary color for all components
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@font-size-small: 12px;
@font-size-lg: 16px;
@line-height-lg: 22px;
@font-weight-bold: 600;
@heading-color: rgba(0, 0, 0, 1); // heading text color
@text-color: rgba(0, 0, 0, 1); // major text color
@text-color-inverse: @gray-1;
@text-color-secondary: @gray-7; // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-radius-card: 10px;
@border-color-card: @gray-5;
@border-color-base: #d9d9d9; // major border color
@border-card: 1px solid @gray-5;
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
@border-radius: 10px;
@color-hint: #a6a6a6;
@lock-overlay-color: #fbfbfb;
@link-color: @blue-4;

//Titles
@font-size-title-h2: 30px;
@line-height-title-h2: 38px;
@font-size-title-h3: 24px;
@line-height-title-h3: 32px;

@font-size-title-medium: 20px;
@line-height-title-medium: 28px;
@font-size-title-small: 16px;
@line-height-title-small: 24px;

// Layout
@layout-header-padding-right: 30px;
@layout-header-padding-left: 30px;

@layout-sider-background: #f5f5f6;
@layout-header-background: @brand-color;
@layout-body-background: #ffffff;
@layout-header-color: #ffffff;
@layout-header-height: 60px;
@layout-header-padding: 0 @layout-header-padding-right 0
  @layout-header-padding-left;
@font-family: "proxima-nova", -apple-system, BlinkMacSystemFont;

// Table
@table-header-bg: transparent;
@table-padding-vertical: 11px;
@table-font-size: 14px;
@table-header-cell-split-color: transparent;

// Tag(custom)
@tag-success-color: @green-1;
@tag-success-bg-color: @lime-7;

@tag-rejected-color: @red-1;
@tag-rejected-bg-color: @volcano-6;

@tag-warning-color: @gray-1;
@tag-warning-bg-color: @orange-5;

@tag-draf-color: @gray-8;
@tag-draf-bg-color: @gray-5;

// Shadow
@shadow-2: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
  0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
@box-shadow-base: @shadow-2;

// Modal
@modal-header-padding-vertical: 60px;
@modal-header-padding-horizontal: 60px;
@modal-header-title-font-size: 30px;
@modal-header-title-line-height: 38px;
@modal-header-border-color-split: transparent;
@modal-footer-border-color-split: transparent;
@modal-footer-padding-vertical: 60px;
@modal-footer-padding-horizontal: 60px;

// Input
@input-border-color: @controls-border-color;
@input-border-secondary-color: @gray-5;
@input-color: @gray-10;

// @input-number-hover-border-color: red;

// Form
@label-color: @gray-9;
@label-required-color: @gray-9;
@form-item-label-height: auto;
@form-item-label-font-size: @font-size-lg;

// Select
@select-border-color: @controls-border-color;

// Tooltip
// @tooltip-bg: @gray-9;
@tooltip-bg: @gray-10;
@tooltip-arrow-width: 8px;

// Grid
@grid-gap: 32px;

// Breakpoints
@screen-xs: 320px;
@screen-sm: 544px;
@screen-md: 768px;
@screen-lg: 992px;
@screen-xl: 1170px;
@screen-xxl: 1440px;
@screen-mobile: @screen-md;

@screen-xs-max: @screen-xs + 1;
@screen-sm-max: @screen-sm + 1;
@screen-md-max: @screen-md + 1;
@screen-lg-max: @screen-lg + 1;
@screen-xl-max: @screen-xl + 1;
@screen-xxl-max: @screen-xxl + 1;
@screen-mobile-max: @screen-mobile + 1;

@breakpoint-mobile: ~"screen and (min-width: @{screen-mobile})";
@breakpoint-mobile-max: ~"screen and (max-width: @{screen-mobile-max})";

//Divider
@divider-color: @gray-4;

// Mixins
.activitiestypes() {
  green: @lime-6;
  transitional: @gold-3;
  enabling: #d3adf7;
}

.max-line(@count) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @count; /* number of lines to show */
  -webkit-box-orient: vertical;
}
