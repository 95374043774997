@import "../../styles/theme.less";

.desktopPopupContainer {
  background-color: none !important;
  :global {
    .mapboxgl-popup-content {
      padding: 0 !important;
      background-color: transparent !important;
      border-radius: 0 !important;
      width: 318px !important;
      border-radius: 8px !important;
    }
    .mapboxgl-popup-tip {
      display: none !important;
    }
  }
}
.mobilePopupContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.65);
}
.pointFeaturePopup {
  width: 100%;

  cursor: default;
  padding: 20px 20px 24px 20px;
  background-color: @gray-9;
  border-radius: 8px;

  @media @breakpoint-mobile-max {
    max-height: 60%;
    border-top-bottom-radius: 0;
    border-top-bottom-radius: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @gray-1 !important;
}
.title {
  color: @gray-1 !important;
}
.itemKey {
  padding: 5px 0;
  border-top: 1px solid @gray-8;
  @media @breakpoint-mobile-max {
    padding: 0;
    border-top: 0;
  }
}
.itemValue {
  color: @gray-1 !important;
  padding: 5px 0 5px 30px;
  border-top: 1px solid @gray-8;
  word-break: break-word;
  @media @breakpoint-mobile-max {
    width: 100%;
    display: block;
    border-bottom: 1px solid @gray-5;
    padding-bottom: 10px !important;
    padding-left: 0;
    margin-bottom: 10px;
    &:last-child {
      border-bottom: none;
    }
  }
}
.list {
  display: grid;
  justify-content: space-between;
  grid-template-columns: minmax(140px, 1fr) minmax(min-content, max-content);
  @media @breakpoint-mobile-max {
    display: block;
  }
}

.closeButton {
  font-size: 16px;
  color: @gray-1 !important;
  transition: color @animation-duration-base @ease-base-out;
  &:hover {
    color: @gray-2 !important;
  }
  &:active {
    color: @gray-3 !important;
  }
  @media @breakpoint-mobile-max {
    font-size: 24px;
  }
}
