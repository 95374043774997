@import "../../styles/theme.less";

.title {
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
  margin-bottom: 20px;
}

.blockTitle {
  display: block;
  font-weight: 600;
  margin-bottom: 20px;
}

.additionalBlock {
  margin-top: 25px;
  margin-left: 30px;
}

.additionalTitle {
  display: block;
  margin-bottom: 10px;
}

.select {
  width: 100%;
}

.input {
  border: 1px solid #D9D9D9 !important;
  height: 40px;
}

.input:focus {
  border: 1px solid #595959 !important;
}

.input:hover {
  border: 1px solid #595959 !important;
}

.checkBox {
  margin-bottom: 10px;
}

.subTitle {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.urlInput {
  display: flex;
}

.inputAdditionalBlock {
  flex-shrink: 0;
  background: #FAFAFA;
  padding: 8px;
  border: 1px solid #D9D9D9 !important;

  font-size: 14px;
  line-height: 22px;

  color: #262626;
}

.inputAdditionalAppend {
  border-left: none !important;
  border-radius: 0px 1px 1px 0px;
}

.inputAdditionalPreppend {
  border-right: none !important;
  border-radius: 1px 0px 0px 1px;
}

.buttons {
  height: 40px !important;
  padding: 5px 16px !important;
  box-sizing: border-box;
}

.externalReview {
  :global {
    .ant-row h3 {
      padding-bottom: 5px !important;
    }
    .ant-typography h5 {
      margin-bottom: 1.5em !important;
    }
  }
  .titleIssuance {
    .ant-typography h5 {
      margin-bottom: 1.5em !important;
    }
  }
}

.externalReview h5 {
  margin-bottom: 20px;
}

.typographyExternal {
  font-size: 16px;
}

.reviewer {
  :global {
    color: @gray-7 !important;
    font-size: 16px !important;
  }
}

.containerDownload {
  color: @blue-6;
  font-size: 16px;
  margin-top: 20px;
  :global {
    .anticon-download {
      margin-right: 10px;
    }
  }
}