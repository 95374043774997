@import "../../styles/theme.less";

.mapLegend {
  width: 100%;
  :global {
    .ant-tabs-nav {
      margin-bottom: 0 !important;
    }
    .ant-tabs-nav-list {
      gap: 5px;
    }
    .ant-tabs-tab {
      background-color: @gray-2 !important;
      padding: 6px 16px 5px;
      box-shadow: inset -1px 0px 0px #f0f0f0, inset 0px 1px 0px #f0f0f0,
        inset 1px 0px 0px #f0f0f0;
      border-radius: 6px 6px 0px 0px;
      margin: 0 !important;
      color: @gray-10 !important;
    }
    .ant-tabs-tab-btn {
      opacity: 0.3;
      transition: opacity @animation-duration-base @ease-base-out;
      color: inherit !important;
      display: flex !important;
      align-items: center;
      gap: 10px;
    }
    .ant-tabs-tab-active {
      border-bottom: none !important;
      .ant-tabs-tab-btn {
        opacity: 1;
      }
    }
    .ant-tabs-ink-bar {
      display: none !important;
    }
    .ant-tabs-nav::before {
      border-bottom: none !important;
    }
    .ant-tabs-nav-operations {
    }
    .ant-tabs-nav-more {
      border-radius: 6px;
      background-color: @gray-2 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 0 !important;
      // width: 35px;
    }
  }

  @media @breakpoint-mobile-max {
    width: 100%;
    max-width: 100%;
  }
}
.activeLayer {
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-radius: 10px;
  border-top-left-radius: 0;
  display: grid;
  gap: 60px;
  align-items: center;
  grid-template-columns: max-content 1fr;
  padding-left: 26px;
  padding-right: 26px;
  overflow: hidden;
  @media @breakpoint-mobile-max {
    height: 170px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
  }
}
.layerInfo {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  padding-right: 10px;
  border-right: 1px solid @divider-color;
  @media @breakpoint-mobile-max {
    padding-right: 0;
    border: none;
  }
}
.tab {
  display: flex;
  align-items: center;
  gap: 10px;
}
.tabIcon {
  width: 14px;
  height: 14px;
  color: @gray-10;
  display: inline-flex;
  svg {
    width: 100%;
    height: 100%;
  }
}
.infoIcon {
  color: @gray-6 !important;
  width: 17px;
  height: 17px;
  @media @breakpoint-mobile-max {
    order: -1;
    align-self: flex-start;
    padding-top: 4px;
  }
}
