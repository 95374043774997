@import "../../styles/theme.less";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  line-height: 0;

  &--large {
    font-size: 32px;
  }
  &--small {
    font-size: 16px;
  }
  &--full {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &--noStyle {
    .spinnerWrapper {
      padding: 0;
    }
  }
  svg {
    color: @primary-color;
    font-size: inherit;
  }
}

.spinnerWrapper {
  padding: 15px 0;
}
