@import "../../styles/theme.less";

.header {
  display: flex;
  justify-content: space-between;
}
.table {
  margin-top: 30px;
}
.footer {
  margin-top: 35px;
}
