.container {
  height: 100%;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 150px;
}
.header {
  flex-shrink: 0;
  flex-grow: 0;
}
.list {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}
