@import "../../styles/theme.less";

@borderRadius: 8px;

.table {
  :global {
    // TH
    .ant-table-thead > tr > th {
      background-color: @gray-5 !important;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      font-size: 12px !important;
      border-bottom: none !important;
      white-space: nowrap;
    }
    .ant-table-thead > tr:first-child th:first-child {
      border-top-left-radius: @borderRadius !important;
    }
    .ant-table-thead > tr:first-child th:last-child {
      border-top-right-radius: @borderRadius !important;
    }
    // TD
    .ant-table-tbody > tr > td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;
    }
    .ant-table-tbody > tr > td:first-child {
      border-left: 1px solid @gray-5;
    }
    .ant-table-tbody > tr > td:last-child {
      border-right: 1px solid @gray-5;
    }
    .ant-table-tbody > tr:last-child > td:first-child {
      border-bottom-left-radius: @borderRadius !important;
    }
    .ant-table-tbody > tr:last-child > td:last-child {
      border-bottom-right-radius: @borderRadius !important;
    }

    .ant-table-row-expand-icon {
      display: none !important;
    }
    .ant-table-column-sorters {
      justify-content: flex-start;
    }
    .ant-table-column-title {
      flex: 0 !important;
    }
    .ant-table-cell-fix-right-first::after,
    .ant-table-cell-fix-right-last::after {
      box-shadow: inset -10px 0 8px -8px #00000026;
    }
    .ant-table-cell-fix-left-first::after,
    .ant-table-cell-fix-left-last::after {
      box-shadow: inset 10px 0 8px -8px #00000026;
    }
    .ant-table-column-sorter {
      margin-left: 5px;
    }
    .ant-table-row-indent {
      display: none !important;
    }
    //TODO: как-то получать level в компоненте
    .ant-table-row-level-1 :local .columnIndent {
      width: 35px;
    }
    .ant-table-row-level-2 :local .columnIndent {
      width: 70px;
    }
  }
}

.container {
  width: 100%;
  overflow: auto;
}
