@import "../../styles/theme.less";

.btn {
  color: @gray-7 !important;
  display: inline-flex !important;
  align-items: center;
  padding: 4px 8px !important;
  &:global(.ant-btn > .anticon + span),
  &:global(.ant-btn > span + .anticon) {
    margin-left: 5px;
  }
  :global .anticon {
    font-size: 24px !important;
    display: flex;
    align-items: center;
  }
  &:hover,
  &:focus {
    color: @blue-6 !important;
    background-color: @blue-1;
  }
  &:active {
    color: @blue-7 !important;
  }
}
