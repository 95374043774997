@import "../../../styles/theme";

.selectObjective {
  :global {
    .ant-select-selector {
      padding-left: 0 !important;
    }
    .ant-select-selection-item {
      font-size: @font-size-small;
    }
  }
}

.dropdownObjectives {
  :global {
    .ant-select-item-option-content {
      font-size: @font-size-small;
    }
  }
}
