@import "src/styles/theme.less";

.input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  &-roundCorners {
    &:global(.ant-input), &:global(.ant-input-affix-wrapper) {
      border-radius: 8px;
    }
  }

  :global{
    .ant-input-suffix {
      color: @gray-5;
    }
  }

  &:global(.ant-input), &:global(.ant-input-affix-wrapper) {
      border: 1px solid @gray-5;
      height: 40px;
      color: @gray-9;
    }

    .ant-input-affix-wrapper {
      height: 40px;

      .ant-input {
        border: none;
        height: auto;
        border-radius: 0;
    }
  }
}
