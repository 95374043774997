.input {
  border: 1px solid #D9D9D9 !important;
  height: 40px;
}

.input:focus {
  border: 1px solid #595959 !important;
}

.processRadio {
  font-size: 16px;
}

.process {
  .title {
    font-size: 20px !important;
  }
}
