@import "../../styles/theme.less";

.productMap {
  position: relative;
  width: 100%;
  height: 442px;

  &--large {
    height: 569px;
  }
  @media @breakpoint-mobile-max {
    height: 350px;
  }
}
