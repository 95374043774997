@import "../../styles/theme.less";

.grid {
  display: grid!important;

  @media @breakpoint-mobile-max {
    grid-template-columns: 1fr;
    grid-gap: 20px 0!important;
  }

  @media @breakpoint-mobile {
    grid-template-columns: 1fr auto auto;
    grid-gap: 0 60px!important;
  }
}