@import "../../../styles/theme.less";

.uploadButton {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.actions {

  button {
    &:hover {
      color: @primary-color;
      background-color: transparent;
    }
  }

  a {
    color: @black;
  }
}