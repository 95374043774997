@import "../../styles/theme.less";

.page {
  width: 3140px;
  padding: 55px 120px 69px;
  box-sizing: border-box;
}

.pagination {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 130%;
  color: @gray-7;
}
.pageTitleWrapper {
  position: relative;
}
.logo {
  position: absolute;
  top: 0;
  right: 0;
  svg {
    display: block;
    width: 324px;
    height: 38.8px;
    color: @gray-6;
  }
}
.pageTitle {
  position: relative;
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 68px;
  margin-top: 40px;
  margin-bottom: 0;
  color: @gray-10;
}
.description {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 22px;
  color: @gray-7;
  margin-top: 25px;
  margin-bottom: 74px;
}
.twoColsList {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-auto-columns: auto;
  row-gap: 20px;
  column-gap: 48px;
}

.companyLevel {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 60px;
}

.taxonomy {
  width: 610px;
}
.overview {
  width: 1000px;
}

.kpiLevel {
  display: grid;
  justify-content: center;

  grid-template-columns: 300px 300px 600px;
  gap: 60px;

  .title {
    grid-column: 1/4;
  }
}

.screeningOverview {
  grid-column: 1/4;
}

