@import "../../styles/theme.less";

.tag {
  border-radius: 14px;
  border: none;
  padding: 0 13px;
  font-size: 12px;
  text-transform: capitalize;
  display: inline-flex;
  justify-content: center;
  &--noMargin {
    margin-right: 0 !important;
  }

  &--completed {
    color: @tag-success-color;
    background-color: @tag-success-bg-color;
  }
  &--approved {
    color: @tag-success-color;
    background-color: @tag-success-bg-color;
  }
  &--sample {
    color: @tag-success-color;
    background-color: @orange-5;
  }
  &--rejected {
    color: @tag-rejected-color;
    background-color: @tag-rejected-bg-color;
  }
  &--issue {
    color: @tag-rejected-color;
    background-color: @tag-rejected-bg-color;
  }
  &--review {
    color: @tag-warning-color;
    background-color: @tag-warning-bg-color;
  }
  &--draft {
    color: @tag-draf-color;
    background-color: @tag-draf-bg-color;
  }
}
