.space {
  &--block {
    &:global(.ant-space) {
        display: flex;
    }
  }
}

.justify {
  &--center {
    &:global(.ant-space) {
      justify-content: center;
    }
  }
}
