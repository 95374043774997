@import "../../styles/theme.less";

.reportingTitle {
  margin-bottom: 20px !important;
}

.title {
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
  margin-bottom: 20px;
}

.blockTitle {
  display: block;
  font-weight: 600;
  margin-bottom: 20px;
}

.additionalBlock {
  margin-top: 35px;
}

.additionalTitle {
  display: block;
  margin-bottom: 10px;
}

.select {
  width: 100%;
}

.input {
  border: 1px solid @gray-5 !important;
  height: 40px;
}

.input:focus {
  border: 1px solid #595959 !important;
}

.input:hover {
  border: 1px solid #595959 !important;
}

.checkBox {
  margin-bottom: 10px;
}

.subTitle {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.summary__row {
  border-bottom: 1px solid @gray-5;
  margin-bottom: 20px;
  :global {
    h5.ant-typography, .ant-typography h5 {
      margin-bottom: 0.9em !important;
    }
  }
}

.summary__rowBody {
  :global {
    .ant-col {
      margin-bottom: 20px;
    }
  }
}