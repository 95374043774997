@import "../../styles/theme.less";

.list {
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  list-style: none;
  margin-bottom: 35px;

  &__item {
    max-width: 200px;
    max-height: 260px;
    :global {
      .ant-card-meta-title {
        color: @gray-10 !important;
        font-style: normal;
        white-space: normal;
        font-size: 14px;
        line-height: 22px;
        text-overflow: clip;
      }
    }
  }
}

.hover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 260px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;

  &--unlock {
    background-color: @blue-9;
  }

  &--lock {
    cursor: auto;
    background-color: @white;
  }

  &:hover {
    opacity: 0.8;
  }

  &__text {
    display: block;
    text-align: center;
    color: @gray-1 !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px !important;

    &--highlite {
      background-color: @blue-9;
      width: 40px;
      padding: 5px;
      border-radius: 8px;
    }

    &--icon {
      font-size: 20px;
    }
  }
}

.item {
  &__btn {
    background: transparent;
    border: none;
  }
}