@import "../../styles/theme.less";

.radio {
  border: 2px solid @gray-4 !important;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px !important;
  display: inline-flex;
  align-items: center;
  & > span {
    padding: 0;
  }
  //TODO: refactor - удалить глоабльные стили
  && {
    margin-right: 0 !important;
  }
  :global {
    .evercity-ui-radio {
      top: 0 !important;
      margin-right: 10px;
    }
  }
  &:global(.evercity-ui-wrapper-disabled) {
    background-color: @gray-3;
    cursor: not-allowed;
    // pointer-events: none;
    .label {
      color: @gray-6;
    }
  }
}
.body {
  display: flex;
  align-items: center;
}
.label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: @gray-9;
}
.icon {
  margin-left: 10px;
  font-size: 18px;
  color: @gray-7 !important;
  transition: color @animation-duration-base @ease-base-out;
  &:hover {
    color: @gray-6;
  }
}
