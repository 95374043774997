@import "../../styles/theme.less";
@import "./CellThemes.less";

.page {
  width: 3140px;
  padding: 55px 120px 69px;
  box-sizing: border-box;
}

.pagination {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 130%;
  color: @gray-7;
}
.pageTitleWrapper {
  position: relative;
}
.logo {
  position: absolute;
  top: 0;
  right: 0;
  svg {
    display: block;
    width: 324px;
    height: 38.8px;
    color: @gray-6;
  }
}
.pageTitle {
  position: relative;
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 68px;
  margin-top: 40px;
  margin-bottom: 0;
  color: @gray-10;
}
.description {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 22px;
  color: @gray-7;
  margin-top: 25px;
  margin-bottom: 74px;
}
.twoColsList {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-auto-columns: auto;
  row-gap: 20px;
  column-gap: 48px;
}
.companyInfoKey {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: @gray-8;
}
.companyInfoValue {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: @gray-10;
}

.table {
  width: 100%;
  margin-top: 15px;
  // pointer-events: none;
  :global {
    table {
      height: 100% !important;
      border: none !important;
    }
    .ant-table-container {
      border: none !important;
    }
    .ant-table-thead {
      tr:nth-child(1) th.ant-table-cell {
        border: none !important;
        padding: 0 !important;
        vertical-align: bottom;
      }
      tr:nth-child(2) th.ant-table-cell {
        vertical-align: top;
        background-color: @blue-5 !important;
        color: @gray-1;
        border-right: 1px solid white;
        border-bottom: none !important;
        height: 100% !important;
        padding: 0 !important;
        &:last-child {
          border-right: none;
        }
        &:first-child {
          vertical-align: middle;
          text-align: center;
        }
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4) {
          vertical-align: middle;
        }
      }
    }
    .ant-table-cell {
      &:nth-child(1) {
        width: auto;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12),
      &:nth-child(13),
      &:nth-child(14),
      &:nth-child(15),
      &:nth-child(16),
      &:nth-child(17) {
        min-width: 88px;
      }
      &:nth-child(18),
      &:nth-child(19),
      &:nth-child(20),
      &:nth-child(21) {
        min-width: 214px;
      }

      &:nth-child(2) {
        max-width: 150px;
      }
    }
    td {
      border-right: 1px solid @gray-7 !important;
      border-bottom: 1px solid @gray-7 !important;
      &:first-child {
        border-left: 1px solid @gray-7 !important;
      }
    }
  }
}

.headerMainCell {
  font-size: 28px;
  line-height: 20px;
  text-align: center;
}

.headerCellWithBrace {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  height: 100%;
  color: @gray-10;
  svg {
    margin-top: 8px;
    width: calc(100% + 2px);
    margin-left: -1px;
    height: auto;
    color: @gray-7;
  }
}

.categroyCell {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: @gray-13;
  padding: 30px;
  text-transform: uppercase;
}

.subcategoryCell {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: @gray-13;
  padding: 30px 20px 30px 56px;
}

.itemNameCell {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  color: @gray-8;
  padding: 30px 20px 30px 56px;
}
.fullWidthCell {
  max-width: 30%;
}

.valueCell {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: @gray-13;
  text-align: center;

  &--large {
    font-size: 25px;
  }
  &--small {
    font-size: 16px;
  }
  &--theme-1 {
    .theme-1();
  }
  &--theme-2 {
    .theme-2();
  }
}
