@import "../../styles/theme.less";

.list {
  display: flex;
  gap: 5px;
  line-height: 1 !important;
  margin-bottom: 0;
  padding-left: 20px;

  &--vertical {
    flex-direction: column;
  }

  &--horizontal {
    flex-direction: row;
    list-style: none;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;

  &__image {
    background-color: currentColor;

    svg {
      path[fill] {
        fill: @gray-1;
      }
      path[stroke] {
        stroke: @gray-1;
      }
    }
  }
}

.icon {
  color: @white;
  font-size: 12px;
  line-height: 20px;
  padding: 0 7px;
  border-radius: 4px;
}