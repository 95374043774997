@import "../../styles/theme.less";

.container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media @breakpoint-mobile-max {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.contentContainer {
  position: relative;
  height: 100%;
  flex-shrink: 1;
}
.header {
  position: relative;
  z-index: 150;
}
.sider {
  background-color: @gray-4 !important;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  z-index: 100;

  :global {
    .ant-layout-sider-zero-width-trigger {
      display: none !important;
    }
    .ant-layout-sider-children {
      overflow: hidden;
    }
  }
  @media screen and (max-width: @screen-md-max) {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
  }
  @media @breakpoint-mobile-max {
    box-shadow: none;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    transform: translateY(0);
    transition: opacity, visiblity,
      trasnform @animation-duration-base @ease-base-out;
    &:global(.ant-layout-sider-collapsed) {
      opacity: 0 !important;
      visibility: hidden !important;
      transform: translateY(30px);
    }
  }
}
.siderCollapseTrigger {
  position: absolute;
  top: 20px;
  left: 100%;
  background-color: @gray-6;
  width: 30px;
  height: 60px;
  z-index: 1;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color @animation-duration-base @ease-base-out;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: @gray-9;
  .anticon {
    transition: transform @animation-duration-base @ease-base-out;
  }
  &--collapsed :global .anticon {
    transform: rotateY(180deg);
  }
  &:hover {
    background-color: @gray-5;
  }
  &:active {
    background-color: @gray-7;
  }
  @media @breakpoint-mobile-max {
    display: none;
  }
}

.content {
  position: relative;
}
.map {
  position: relative;
  height: 100%;
  z-index: 0;
}

.mobileOnly {
  @media @breakpoint-mobile {
    display: none;
  }
}
