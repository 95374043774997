@import "../../styles/theme.less";

.roadMap {
  :global {
    .ant-steps-item-title {
      color: @text-color !important;
      font-size: 16px !important;
      line-height: 24px !important;
      font-weight: @font-weight-bold;
    }

    .ant-steps-item-description {
      color: @gray-7 !important;
      font-size: 16px !important;
      line-height: 24px !important;
    }
  };
}