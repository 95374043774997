@import "../../styles/theme.less";

.mapButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: @gray-1;
  font-size: 12px;
  transition: background-color @animation-duration-base @ease-base-out;
  &:hover {
    background-color: @gray-2;
  }
  &:active {
    background-color: @gray-3;
  }

  &--default {
    border-radius: 8px;
    width: 50px;
    height: 50px;
  }
  &--small {
    border-radius: 6px;
    width: 35px;
    height: 35px;
  }
}
